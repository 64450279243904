import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Assets

import defaults from '../../constants/defaults';

import Redirections from '../../constants/routes';

import * as assets from '../../assets';

import { elements, materials } from '../../components';

const Front = () => {
    
    const [pageReady, setpageReady] = useState(false);    

    useEffect(() => {

        // When page is ready set to:
        // setPageReady(true);

    }, [pageReady])


    useEffect( () => {
        setpageReady(true);
    }, []) 
   

    return (
        <elements.PageContainer 
            pageReady={true}
            pageTitle={defaults.tabbar.GENERAL}
            sticky={true}
            cover={true}
            menu={'access'}
        >
            <LeftRightBlockers>


                <elements.CallToActionSide
                    titleOne={(<>
                        
                        <BigTitleSub>Automate statistic</BigTitleSub>
                    </>)}
                    titleTwo={(
                        <>
                            <BigTitleSub>based marketing</BigTitleSub>
                        </>
                    )}
                    subTitle={'Use economic data to build expertise reliant brands.'}
                    callToActionText={`Begin for free`}
                    callToActionTextLink={Redirections.ROUTE_LINK_JOIN.partial}
                />


                <AnotherElement>

                    <InternalElement>
                        <InternalElementExample src={assets.EmailExample}/>
                    </InternalElement>

                </AnotherElement>
             

            </LeftRightBlockers>


        </elements.PageContainer>
    )

}

export default Front;

const  LeftRightBlockers = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
    height:100%;
    width:100%;
    min-width:1000px;
`

const AnotherElement = styled.div`
    width:50%;
    height:100%;
    padding-right:50px;
    z-index:1000000;
    padding-top:150px;
`

const InternalElement = styled.div`
    width:100%;
    height:100%;
    background:#ffffff;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px -7px 14px 0px, rgba(0, 0, 0, 0.12) 0px -3px 6px 0px;
    overflow-y:scroll;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    box-sizing:border-box;
    padding:0;
    margin:0;   
`

const InternalElementExample = styled.img`
    width:100%;
    height: auto;
    top:0;
    position:relative;
    display:flex;
    object-fill:contain;
    object-position: top;
    box-sizing:border-box;
    
    padding:0;
    margin:0;
    margin-top:-30px;
   
`


const StepExplaination = styled.div`
    background:#f5fafb;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
     
`

const AnotherSection = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:500px;
    width:100%;
`


const SectionNextShadow = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
    padding-bottom:50px;
    padding-top: 50px;
    min-width:500px;
    padding-left:${props => props.addSidePadding ? 50 : 0 }px;
    padding-right:${props => props.addSidePadding ? 50 : 0 }px;
    ${props => {
        if (props.shadow === 'below') {
            return (`box-shadow: rgba(249, 249, 249, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;`)
        } else if (props.shadow === 'above') {
            return (`box-shadow: rgba(249, 249, 249, 0.12) 0px -9px 14px 0px, rgba(0, 0, 0, 0.12) 0px -5px 6px 0px;`)
        }
    }}
    
`


const BackgroundImage = styled.div`
    background: #0092FF;
    width:100%;
    height: 100%;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    flex-grow:0;
    flex-shrink:0;
    position:relative;
`




const SectionNext = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
`


const BigTitleSub = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
    padding:0;
    margin:0;
    font-family: newFontFaceLight, Arial, sans-serif;
    color:#f9f9f9;
`


const LogoName = styled.img`
    display:flex;
    flex-direction:row;
    object-fill:contain;
    box-sizing:border-box;
    height:60px;
    padding:0;
    margin:0;
    margin-top:10px;
    padding-right:20px;

`
