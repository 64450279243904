import styled from 'styled-components';

import { 
    FormatListBulletedRounded, 
    BarChart,
    AssistantPhotoRounded, 
    ChevronRight,  
    Message,
    ExitToApp, 
    Search,
    WarningSharp,
    Send,
    CloudDownloadRounded,
    ArrowBack, 
    Clear, 
    CreditCardRounded,
    Done,
    Person,
    SecurityRounded,
    Add,
    HomeOutlined,
    RepeatOutlined,
    EmailOutlined,
    BrushOutlined,
    CloudUploadOutlined,
    Edit,
    BlurOn,
    ChevronLeft,
    Link,
    LinkOff,
    PublishRounded,
    ArrowDropDown,
    Title,
    Notes,
    Image,
    CancelOutlined,
    Mouse,
    AlternateEmail,
    ViewComfyRounded,
    Launch,
    Star,
    StarBorder,
} from '@material-ui/icons';

// ICON STYLES

const TitleStyled = styled(Title)`
    && {
        fill: #000000;
        width: 25px;
        height: 25px;
        transition: all 0.2s;
    }
`


const NotesStyled = styled(Notes)`
    && {
        fill: #000000;
        width: 25px;
        height: 25px;
        transition: all 0.2s;
    }
`

const ImageStyled = styled(Image)`
    && {
        fill: #000000;
        width: 25px;
        height: 25px;
        transition: all 0.2s;
    }
`

const MouseStyled = styled(Mouse)`
    && {
        fill: #000000;
        width: 25px;
        height: 25px;
        transition: all 0.2s;
    }
`


const BarChartStyled = styled(BarChart)`
    && {
        fill: #ffffff;
        width: 25px;
        height: 25px;
        transition: all 0.2s;
    }
`


const PublishedStyled = styled(PublishRounded)`
    && {
        fill: #ffffff;
        width: 25px;
        height: 25px;
        transition: all 0.2s;
    }
`

const PublishedBlueStyled = styled(PublishRounded)`
    && {
        fill: rgb(0, 146, 255);
        width: 25px;
        height: 25px;
        transition: all 0.2s;
    }
`


const ArrowDropDownStyled = styled(ArrowDropDown)`
    && {
        fill: #000000;
        width: 25px;
        height: 25px;
        transition: all 0.2s;
    }
`



const LinkNewStyled = styled(Link)`
    && {
        fill: #ffffff;
        width: 20px;
        height: 20px;
        transition: all 0.2s; 
    }
`

const LinkNewOffStyled = styled(LinkOff)`
    && {
        fill: #a9a9a9;
        width: 20px;
        height: 20px;
        transition: all 0.2s; 
    }
`


const AlternateEmailStyled = styled(AlternateEmail)`
    && {
        fill: #000000;
        width: 20px;
        height: 20px;
        transition: all 0.2s; 
    }
`

const ViewComfyRoundedStyled = styled(ViewComfyRounded)`
    && {
        fill: #000000;
        width: 20px;
        height: 20px;
        transition: all 0.2s; 
    }
`

const LaunchStyled = styled(Launch)`
    && {
        fill: #000000;
        width: 20px;
        height: 20px;
        transition: all 0.2s; 
    }
`


const LinkStyled = styled(Link)`
    && {
        fill: #ffffff;
        width: 25px;
        height: 25px;
        transition: all 0.2s; 
    }
`

const LinkOffStyled = styled(LinkOff)`
    && {
        fill: #ffffff;
        width: 25px;
        height: 25px;
        transition: all 0.2s; 
    }
`


const SearchStyled = styled(Search)`
    && {
        fill: #ffffff;
        width: 25px;
        height: 25px;
        transition: all 0.2s; 
    }
`

const AssistantStyled = styled(AssistantPhotoRounded)`
    && {
        fill: #090909;
        font-size:20px;
    }
`

const BlurOnStyled = styled(BlurOn)`
    && {
        fill: #090909;
        font-size:20px;
    }
`

const HomeStyled = styled(HomeOutlined)`
    && {
        fill: #ffffff;
        font-size:20px;
    }
`

const MessageStyled = styled(Message)`
    && {
        fill: #090909;
        font-size:20px;
    }
`

const MessageStyledWhite = styled(Message)`
    && {
        fill: #FFFFFF;
        font-size:20px;
    }
`

const UploadWhite = styled(CloudUploadOutlined)`
    && {
        fill: #FFFFFF;
        font-size:40px;
    }
`

const EditWhite = styled(Edit)`
    && {
        fill: #FFFFFF;
        font-size:40px;
    }
`

const EditBlue = styled(Edit)`
    && {
        fill: blue;
        font-size:15px;
        transition: all 0.2s;
    }
`

const EditOtherBlue = styled(Edit)`
    && {
        fill: rgb(0, 146, 255);
        font-size:15px;
        transition: all 0.2s;
    }
`

const ChevronLeftBlueStyled = styled(ArrowBack)`
    && {
        fill: blue;
        font-size:15px;
        transition: all 0.2s;
    }
`

const ExitToAppStyled = styled(ExitToApp)`
    && {
        fill: #090909;
        font-size:20px;
    }
`

const ChevronRightStyled = styled(ChevronRight)`
    && {
        fill: #090909;
        font-size:40px;
    }
`

const ChevronLeftStyled = styled(ChevronLeft)`
    && {
        fill: #090909;
        font-size:40px;
    }
`

const ChevronRightBlueStyled = styled(ChevronRight)`
    && {
        fill: rgb(0, 146, 255);
        font-size:40px;
    }
`




const FormatListBulletedRoundedStyled = styled(FormatListBulletedRounded)`
    && {
        fill: #ffffff;
        width: 25px;
        height: 25px;
        transition: all 0.2s;
        
    }
`

const ErrorSymbol = styled(WarningSharp)`
    && {
        fill: red;
        font-size: 20px;
        transition: all 0.5s;
    }
`

const WarningSymbolStyled = styled(WarningSharp)`
    && {
        fill: rgb(255, 127, 127);
        font-size: 15px;
        transition: all 0.5s;
        margin-left:10px;
    }
`

const SendStyled = styled(Send)`
    && {
        fill: #ffffff;
        font-size:25px;
    }
`


const EmailStyled = styled(EmailOutlined)`
    && {
        fill: #ffffff;
        font-size:25px;
    }
`

const BrushStyled = styled(BrushOutlined)`
    && {
        fill: #ffffff;
        font-size:25px;
    }
`


const RepeatStyled = styled(RepeatOutlined)`
    && {
        fill: #ffffff;
        font-size:25px;
    }
`





const CloudDownloadRoundedStyled = styled(CloudDownloadRounded)`
    && {
        fill: #ffffff;
        font-size:20px;
        transition: all 0.2s;
    }
`


const CreditCardRoundedStyled = styled(CreditCardRounded)`
    && {
        fill: ${props => props.active === 'true' ? `#ffffff` : `#090909`};
        font-size:25px;
        transition: all 0.2s;
    }
`

const ChevronRightStyledSmall = styled(ChevronRight)`
    && {
        fill: #090909;
        font-size:20px;
    }
`

const AddStyledWhite = styled(Add)`
    && {
        fill: #ffffff;
        font-size:25px;
    }
`

const AddStyledLightWhite = styled(Add)`
    && {
        fill: #ffffff;
        font-size:15px;
        opacity:0.8;
    }
`


const CancelFullStyled = styled(CancelOutlined)`
    && {
        fill: #ffffff;
        font-size:20px;
        transition: all 0.2s;
    }
`

const CancelOutlinedStyled = styled(Clear)`
    && {
        fill: #ffffff;
        font-size:20px;
        transition: all 0.2s;
    }
`

const CancelOutlinedStyledMini = styled(Clear)`
    && {
        fill: #FF7F7F;
        font-size:15px;
        transition: all 0.2s;
    }
`

const CancelOutlinedStyledLarge = styled(Clear)`
    && {
        fill: #FF7F7F;
        font-size:30px;
        transition: all 0.2s;
    }
`

const CancelOutlinedStyledRed = styled(Clear)`
    && {
        fill: #FF7F7F;
        font-size:20px;
        transition: all 0.2s;
    }
`



const CancelOutlinedStyledDark = styled(Clear)`
    && {
        fill: #090909;
        opacity:0.5;
        font-size:20px;
        transition: all 0.2s;

        &:hover {
            opacity:0.9;
        }

        &:active {
            opacity:1.0;
        }
    }
`

const ArrowBackStyled = styled(ArrowBack)`
    && {
        fill: #000000;
        font-size:20px;
        transition: all 0.2s;
    }
`


const DoneStyled = styled(Done)`
    && {
        fill: green;
        font-size:20px;
        transition: all 0.2s;
    }
`

const DoneWhiteStyled = styled(Done)`
    && {
        fill: #FFFFFF;
        font-size:20px;
        transition: all 0.2s;
    }
`

const DoneWhiteSmallStyled = styled(Done)`
    && {
        fill: #FFFFFF;
        font-size:15px;
        transition: all 0.2s;
    }
`

const DoneSmallStyled = styled(Done)`
    && {
        fill: rgb(0,205,122);
        font-size:15px;
        transition: all 0.2s;
        margin-left:10px;
    }
`

const ClearStyledRed = styled(Clear)`
    && {
        fill: red;
        font-size:20px;
        transition: all 0.2s;
    }
`


const PersonStyled = styled(Person)`
    && {
        fill: ${props => props.active === 'true' ? `#ffffff` : `#090909`};
        font-size:25px;
        transition: all 0.2s;
    }
`


const SecurityRoundedStyled = styled(SecurityRounded)`
    && {
        fill: ${props => props.active === 'true' ? `#ffffff` : `#090909`};
        font-size:25px;
        transition: all 0.2s;
    }
`



const StarStyled = styled(Star)`
    && {
        fill: #1676F3;
        font-size:30px;
        cursor:pointer;

        &:hover {
            opacity:0.9;
        }
    }
`


const StarBorderStyled = styled(StarBorder)`
    && {
        fill: #1676F3;
        font-size:30px;
        cursor:pointer;

        &:hover {
            opacity:0.9;
        }
    }
`





// EXPORTS

export {
    FormatListBulletedRoundedStyled,
    ChevronRightStyled,
    ExitToAppStyled,
    MessageStyled,
    AssistantStyled,
    SearchStyled,
    BarChartStyled,
    ErrorSymbol,
    SendStyled,
    HomeStyled,
    ChevronRightStyledSmall,
    CloudDownloadRoundedStyled,
    CreditCardRoundedStyled,
    CancelOutlinedStyled,
    CancelOutlinedStyledRed,
    ArrowBackStyled,
    MessageStyledWhite,
    DoneStyled,
    ClearStyledRed,
    PersonStyled,
    SecurityRoundedStyled,
    AddStyledWhite,
    EmailStyled,
    RepeatStyled,
    StarStyled,
    StarBorderStyled,
    BrushStyled,
    UploadWhite,
    EditBlue,
    EditWhite,
    CancelOutlinedStyledDark,
    CancelOutlinedStyledMini,
    BlurOnStyled,
    ChevronRightBlueStyled,
    ChevronLeftStyled,
    LinkStyled,
    LinkOffStyled,
    PublishedStyled,
    PublishedBlueStyled,
    DoneWhiteStyled,
    ChevronLeftBlueStyled,
    CancelOutlinedStyledLarge,
    ArrowDropDownStyled,
    TitleStyled,
    NotesStyled,
    CancelFullStyled,
    MouseStyled,
    ImageStyled,
    AddStyledLightWhite,
    EditOtherBlue,
    LinkNewStyled,
    LinkNewOffStyled,
    AlternateEmailStyled,
    ViewComfyRoundedStyled,
    LaunchStyled,
    DoneWhiteSmallStyled,
    WarningSymbolStyled,
    DoneSmallStyled
}