
import * as evaluate from '../../validators';

const sendForm = (
    action,
    formArray, 
    helperArray, 
    resetArray, 
    constraints, 
    spinner,
    generalHelper,
    callback) => {

    const preSubmissionErrors = evaluate.evaluate(formArray, constraints);

    evaluate.sanitizeForm(formArray, preSubmissionErrors, (err, formArraySanitized) => {

        if (!err) {
            for (const [key, value] of Object.entries(formArraySanitized)) {
            
                if (!!value.error) {
                    helperArray[key](value.error)
                }
            }


            return null
        }

        for (const [, value] of Object.entries(helperArray)) {
            
            value([])

        }

        // Begin spinner

        spinner(true);

        action(formArraySanitized, (success, prompt, override, error) => {

            spinner(false);

            if (override) {

                generalHelper([prompt])

                return callback(false)
            }

            generalHelper([])

            if (success) {
                for (const [, value] of Object.entries(resetArray)) {
                    
                    if (value.shouldReset) {
                        value.setterFunction(value.setterValue)
                    }
        
                }

                return callback(true, prompt)
            }



            if (error !== null) {

                // Later decide if client should determine this description instead of server
                helperArray['email']([error.description])

                return callback(false)
            }

            

            evaluate.sanitizeForm(formArraySanitized, prompt, (err, formArraySanitizedFinal) => {
                
                if (!err) {

                    for (const [key, value] of Object.entries(formArraySanitizedFinal)) {
                    
                        if (!!value.error) {
                            helperArray[key](value.error)
                        }
                    }
        
                }

                return callback(false)

            })

        })
       

    })
}

const database = { sendForm };

export default database;