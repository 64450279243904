import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';

import moment from 'moment-timezone';

import defaults from '../../constants/defaults';

import Redirections from '../../constants/routes';

import { Link } from 'react-router-dom';


import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../state/actions';

import { database, format } from '../../services';
import * as evaluate from '../../validators';

import * as assets from '../../assets';

import useInput from '../../hooks/useInput';

import { elements, materials, icons, inputs } from '../../components';
import { FormatColorReset, LensTwoTone } from '@material-ui/icons';


const UpdateLabel = ({type, index, showDelete, styleName, styleValue, activeElement, activeSubBlockElement, activeIndividualElement, updateElement}) => {

    const { value:styleValueInternal, bind:bindStyleValueInternal, reset:resetStyleValueInternal } = useInput(styleValue);

    const [ styleValueInternalHelper, setStyleValueInternalHelper ] = useState([]);

    const deleteElement = () => {
        updateElement(activeElement, activeSubBlockElement, activeIndividualElement, type, null, {
            name: styleName,
            value: 'delete'
        });
    }

    useEffect( () => {

        if (styleValueInternal !== styleValue) {
            updateElement(activeElement, activeSubBlockElement, activeIndividualElement, type, null, {
                name: styleName,
                value: styleValueInternal
            });
        }
        
        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[styleValueInternal])

    return (
        <LabelOverall key={index}>
            <LabelName>{styleName}</LabelName>

           
            <inputs.BasicTextInput 
                title={``}
                placeholder={``}
                ready={true}
                type={`text`}
                bind={bindStyleValueInternal} 
                value={styleValueInternal}
                additionalStyling={`border:1px solid #a9a9a9;text-align:center;justify-content:center;align-items:center;`}
                autoFocusOnMount={true}
                symbolRight={null}
                setWidth={'100%'}
                typeOf={'center'}
                errorMessage={styleValueInternalHelper}
                tabIndex={1}
            />

            {
                (type !== 'defaults' && showDelete) && (
                    <ButtonLittle type={'edit'} onClick={deleteElement} backgroundCol={'#FF7F7F'}>
                        <icons.CancelOutlinedStyledMini />
                    </ButtonLittle>
                    
                )
            }
            
        </LabelOverall>
    )

}


const ListOfOptions = ({updateElement, activeElement, activeSubBlockElement, activeIndividualElement, activeDesign }) => {

    let readyState = false;

    let styling = [];
    let height = null;
    let width = null;

    const [showDelete, setShowDelete] = useState(false);

    const [ chosenNewStyleHelper, setChosenNewStyleHelper ] = useState([]);

    const { value:chosenNewStyle, bind:bindChosenNewStyle, reset:resetChosenNewStyle } = useInput(``);

    const styleOptions = [ { 
        name: `background`, 
        value: `#FFFFFF`,
    }, { 
        name: `font-size`, 
        value: `14px`,
    }, {   
        name: `padding`, 
        value: `0px`,
    },
    { 
        name: `margin`, 
        value: `0px`,
    },
    {
        name: `border-bottom-left-radius`,
        value: `0px`
    },
    {
        name: `border-bottom-right-radius`,
        value: `0px`
    },
    {
        name: 'border-radius',
        value: '0px'
    },
    {
        name: 'color',
        value: '#000000'
    }];

    const [ activeStyleOptions, setActiveStyleOptions ] = useState([]);

    


    useEffect(() => {

        if (activeElement !== null && activeSubBlockElement !== null) {

            let stylesBeingUsed = []

            // EACH SUBBLOCK STYLING
            
            if (activeIndividualElement === null) {

                if (!activeDesign[activeElement]) {
                    if ("styles" in activeDesign[activeElement].subBlocks[activeSubBlockElement]) {
                        stylesBeingUsed= activeDesign[activeElement].subBlocks[activeSubBlockElement].styles.map((val) => val.name);
                    }
                }
               
            
            // INDIVIDUAL ELEMENT STYLING

            } else if (activeIndividualElement !== null) {

                if (!activeDesign[activeElement]) {
                    if ("styles" in activeDesign[activeElement].subBlocks[activeSubBlockElement].content[activeIndividualElement]) {
                        stylesBeingUsed = activeDesign[activeElement].subBlocks[activeSubBlockElement].content[activeIndividualElement].styles.map((val) => val.name);
                    }
                }
                
            }

            const stylesAvailable = styleOptions.map((val) => val.name);
            const stylesToProvide = stylesAvailable.filter((val) => (stylesBeingUsed.includes(val) === false));
            
            setActiveStyleOptions(stylesToProvide);

        }


        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeElement, activeSubBlockElement, activeIndividualElement]);

    useEffect(() => {
        resetChosenNewStyle(styleOptions[0].name);

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const addChosenStyle = () => {

        const defaultStyleSetting = styleOptions.find((val) => val.name === chosenNewStyle );

        if (defaultStyleSetting !== undefined) {
            updateElement(activeElement, activeSubBlockElement, activeIndividualElement, null, null, defaultStyleSetting);
        }


        if (activeElement === null) {
            readyState = false;

        } else {
            readyState = true;

            if (activeSubBlockElement !== null && activeIndividualElement !== null) {
                styling = activeDesign[activeElement].subBlocks[activeSubBlockElement].content[activeIndividualElement].styles;
                

            } else if (activeSubBlockElement !== null && activeIndividualElement === null) {

                console.log('WE ARE HERE 1: ', activeDesign);
                console.log('WE ARE HERE 2: ', activeElement);
                console.log('WE ARE HERE 3: ', activeSubBlockElement);

                styling = activeDesign[activeElement].subBlocks[activeSubBlockElement].styles;
                height = activeDesign[activeElement].height;
                width = activeDesign[activeElement].subBlocks[activeSubBlockElement].width;
            
            }


        }

        styling = styling.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }
            return 0
        })


    }




    return (

       
        <ListOfOptionsGroup>

            {
                (readyState && activeIndividualElement === null) ? (
                    <>
                        <HeadingComponent>Default styling</HeadingComponent>
                        <UpdateLabel type={'defaults'} showDelete={showDelete} styleName={'height'} styleValue={height} activeElement={activeElement} activeSubBlockElement={activeSubBlockElement} activeIndividualElement={activeIndividualElement} updateElement={updateElement} />
                        <UpdateLabel type={'defaults'} showDelete={showDelete} styleName={'width'} styleValue={width} activeElement={activeElement} activeSubBlockElement={activeSubBlockElement} activeIndividualElement={activeIndividualElement} updateElement={updateElement} />
                    </>
                ) : (
                    <></>
                )
            }

           
            
            {
                readyState ? (

                    <>
                        <HeadingComponent>
                            Specific styling
                            <ButtonOnRightHeading>
                                {   showDelete ? 
                                    ( <icons.ChevronLeftBlueStyled onClick={() => setShowDelete(false)} />) :
                                    ( <icons.EditBlue onClick={() => setShowDelete(true)} /> )
                                      
                                }
                            </ButtonOnRightHeading>
                        </HeadingComponent>
                       
                        {
                            styling.length > 0 ? 
                                styling.map((val, index) => (
                                    <UpdateLabel key={index} showDelete={showDelete} index={index} styleName={val.name} styleValue={val.value} activeElement={activeElement} activeSubBlockElement={activeSubBlockElement} activeIndividualElement={activeIndividualElement} updateElement={updateElement} />
                                ))
                                : <LabelOtherName key={'other'}>Choose a styling option <br />from the list below</LabelOtherName>
                        }

                        {
                            showDelete ? (
                              <AddNewStyle>
                                <inputs.DropDownInput 
                                    title={`Email Design`}
                                    placeholder={``}
                                    ready={!true}
                                    type={`text`}
                                    bind={bindChosenNewStyle} 
                                    options={activeStyleOptions}
                                    value={chosenNewStyle}
                                    additionalStyling={`
                                        background:#ffffff;
                                        margin-right:10px;
                                    `}
                                    autoFocusOnMount={true}
                                    symbolRight={null}
                                    errorMessage={chosenNewStyleHelper}
                                    tabIndex={1}
                                />
                                <ButtonAdd type={'edit'} onClick={addChosenStyle} backgroundCol={'rgb(0, 205, 112)'}>
                                    Add
                                </ButtonAdd>
                             </AddNewStyle>
                            ) : null
                        }
                        
                    </>
                ) : (
                    <LabelOtherName key={'other'}>This element cannot be styled. Please select another element.</LabelOtherName>
                )
            }
        </ListOfOptionsGroup>);
}



const BoundaryWalls = ({addDrop, elementActive, activeElement, activeSubBlockElement}) => {
    return (
        <>
            <InnerBlockLeft active={elementActive}>
                <MiniButton onClick={() => addDrop('left', activeElement, activeSubBlockElement, null)} active={true}><icons.AddStyledLightWhite /></MiniButton>    
            </InnerBlockLeft>
            <InnerBlockRight active={elementActive}>
                <MiniButton onClick={() => addDrop('right', activeElement, activeSubBlockElement, null)} active={true}><icons.AddStyledLightWhite /></MiniButton>    
            </InnerBlockRight>
            <InnerBlockTop active={elementActive}>
                <MiniButton onClick={() => addDrop('up', activeElement, activeSubBlockElement, null)} active={true}><icons.AddStyledLightWhite /></MiniButton>    
            </InnerBlockTop>
            <InnerBlockBottom active={elementActive}>
                <MiniButton onClick={() => addDrop('down', activeElement, activeSubBlockElement, null)} active={true}><icons.AddStyledLightWhite /></MiniButton>    
            </InnerBlockBottom>
        </>
    )
}

const InputElementToUse = ({
    styling, 
    value, 
    updateElement, 
    addLinkToDesign,
    index,
    type,
    publishedStatus,
    subBlockIndex, 
    individualIndex,
    links,
    linksAvailable,
    activeSubBlockElement, 
    setActiveSubBlockElement, 
    activeIndividualElement, 
    setActiveIndividualElement,
    removeIndividualElement
}) => {

    const { value:text, bind:bindText, reset:resetText } = useInput(value);

    const [ inElementEdit, setInElementEdit ] = useState(false);

    const [ textHelper, setTextHelper ] = useState([]);

    useEffect( () => {

        if (!publishedStatus) {
            updateElement(index, subBlockIndex, individualIndex, null, text, null);
        }
        
        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[text])


    const updateTypeForElement = (typeChosen) => {
        if (!publishedStatus) {

            let newContent = {
                type: 'text',
                value: '',
                links: [],
                styles: []
            }

            switch (typeChosen) {
                case 'text':
                    newContent = {
                        type: 'text',
                        value: '',
                        links: [],
                        styles: [{

                        }]
                    }
                    break;
                case 'para':
                    newContent = {
                        type: 'para',
                        value: '',
                        links: [],
                        styles: []
                    }
                    break;
                case 'img':
                    newContent = {
                        type: 'img',
                        value: '',
                        links: [],
                        styles: []
                    }
                    break;
                case 'btn':
                    newContent = {
                        type: 'btn',
                        value: 'Call to action',
                        links: [],
                        styles: [
                            {
                                name: 'background',
                                value: 'rgb(60, 181, 152)'
                            },
                            {
                                name: 'border-radius',
                                value: '50px'
                            },
                            {
                                name: 'color',
                                value: '#ffffff'
                            },
                            {
                                name: 'box-shadow',
                                value: 'rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px'
                            },
                            {
                                name: 'padding',
                                value: '10px'
                            },
                        ]
                    }
                    break;
                default:
                    break;
            }

            // Need to update the server and main object with new type. Load default styling
            updateElement(index, subBlockIndex, individualIndex, 'updateType', newContent, null);

        }
    }

    const addLinkToElement = (urlID) => {
        updateElement(index, subBlockIndex, individualIndex, 'links', urlID, null);
    }

    const removeFocus = () => {
        // console.log('hello');
        // setActiveIndividualElement(null);
    }

    const displayResult = (type) => {

        if (!publishedStatus) {
            
            // RETURN UNPUBLISHED OUTCOME

            switch (type) {
                case 'text':
                    return (
                        <div key={index} onClick={() => {
                            if (!publishedStatus) {
                                setActiveIndividualElement(individualIndex)
                            } 
                        }}>
                            <inputs.EmailDesignBasicTextInput 
                                title={``}
                                placeholder={`Type text here...`}
                                ready={true}
                                type={`text`}
                                bind={bindText}
                                value={text}
                                additionalStyling={styling.map((val) => `${val.name}: ${val.value}`).join(';')}
                                autoFocusOnMount={true}
                                symbolRight={null}
                                errorMessage={textHelper}
                                onBlur={removeFocus}
                                tabIndex={1}
                            />
                        </div>
                    )
                case 'para':
                    return (
                        <div key={index} onClick={() => {
                            if (!publishedStatus) {
                                setActiveIndividualElement(individualIndex)
                            } 
                        }}>
                             <inputs.EmailDesignBasicTextInput 
                                title={``}
                                placeholder={`Type text here...`}
                                ready={true}
                                type={`text`}
                                bind={bindText}
                                value={text}
                                additionalStyling={styling.map((val) => `${val.name}: ${val.value}`).join(';')}
                                autoFocusOnMount={true}
                                symbolRight={null}
                                errorMessage={textHelper}
                                onBlur={removeFocus}
                                tabIndex={1}
                            />
                        </div>
                    )
                case 'img':
                    return (
                        <div key={index} onClick={() => {
                            if (!publishedStatus) {
                                setActiveIndividualElement(individualIndex)
                            } 
                        }}>
                            <></>
                        </div>
                    )
                case 'btn':
                    return (
                        <div key={index} onClick={() => {
                            if (!publishedStatus) {
                                setActiveIndividualElement(individualIndex)
                            } 
                        }}>
                            <inputs.EmailDesignBasicTextButtonInput 
                                title={``}
                                placeholder={`Add button here...`}
                                ready={true}
                                type={`text`}
                                bind={bindText}
                                value={text}
                                additionalStyling={styling.map((val) => `${val.name}: ${val.value}`).join(';')}
                                autoFocusOnMount={true}
                                symbolRight={null}
                                errorMessage={textHelper}
                                onBlur={removeFocus}
                                activateDropdown={true}
                                tabIndex={1}
                                addLinkToDesign={addLinkToDesign}
                                addLinkToElement={addLinkToElement}
                                linksAvailable={linksAvailable}
                                links={links}
                            />
                        </div>
                    )
                default:
                    return (
                            <AnotherOptionDisplay>
                            <NonSelectedBox key={index} active={inElementEdit} onClick={() => {
                                    if (!publishedStatus) {
                                        setActiveIndividualElement(individualIndex)
                                        setInElementEdit(!inElementEdit)
                                    } 
                                }}>
                                    <ChooseType active={inElementEdit}>
                                        <ListOfOptionsGroupOther>

                                            <OptionToDragOuter onClick={() => updateTypeForElement('text')}>
                                                <OptionToDragOther>
                                                    <OptionTop><icons.TitleStyled /></OptionTop>
                                                    <OptionBottomOther>Title</OptionBottomOther>
                                                </OptionToDragOther>
                                            </OptionToDragOuter>

                                            <OptionToDragOuter onClick={() => updateTypeForElement('para')}>
                                                <OptionToDragOther>
                                                    <OptionTop><icons.NotesStyled /></OptionTop>
                                                    <OptionBottomOther>Paragraph</OptionBottomOther>
                                                </OptionToDragOther>
                                            </OptionToDragOuter>


                                            <OptionToDragOuter onClick={() => updateTypeForElement('img')}>
                                                <OptionToDragOther>
                                                    <OptionTop><icons.ImageStyled /></OptionTop>
                                                    <OptionBottomOther>Image</OptionBottomOther>
                                                </OptionToDragOther>
                                            </OptionToDragOuter>

                                            <OptionToDragOuter onClick={() => updateTypeForElement('btn')}>
                                                <OptionToDragOther>
                                                    <OptionTop><icons.MouseStyled /></OptionTop>
                                                    <OptionBottomOther>Button</OptionBottomOther>
                                                </OptionToDragOther>
                                            </OptionToDragOuter>

                                        </ListOfOptionsGroupOther>

                                    </ChooseType>
                                    <NSBText>Choose type</NSBText>
                                    <icons.ArrowDropDownStyled />
                            </NonSelectedBox>
                            
                            <ButtonLittlePositioned type={'edit'} onClick={() => removeIndividualElement(index, subBlockIndex, individualIndex)} backgroundCol={'#FF7F7F'}>
                                <icons.CancelOutlinedStyledRed />
                            </ButtonLittlePositioned>
                            </AnotherOptionDisplay>
                    )
            }

        } else {

            // RETURN PUBLISHED OUTCOME

            switch (type) {
                case 'text':
                    return (
                        <TextDisplay key={index} additionalStyling={styling.map((val) => `${val.name}: ${val.value}`).join(';')}>
                            {text}
                        </TextDisplay>
                    )
                case 'para':
                    return (
                        <TextDisplay key={index} additionalStyling={styling.map((val) => `${val.name}: ${val.value}`).join(';')}>
                            {text}
                        </TextDisplay>
                    )
                case 'img':
                    return (
                        <div>IMAGE</div>
                    )
                case 'btn':
                    return (
                        <div>IMAGE</div>
                    )
                default:
                    return null
            }
        }

       
    }


    return displayResult(type);
    
}


const EmailBlock = ({ addLinkToDesign, designLinks, publishedStatus, updateElement, blockLength, detail, activeElement, setActivateElement, activeSubBlockElement, setActiveSubBlockElement, activeIndividualElement, setActiveIndividualElement, elementActive, isDragging, onDragEnd, onDragEnter, onDragOver, onDrop, currentDragOver, firstCurrentDragOver, index, removeSubBlockElement, removeIndividualElement, addDrop}) => {

    const [blockHeight, setBlockHeight] = useState(!!detail ? detail.height : 0);

    const [isEditable, setIsEditable] = useState(false);

    const [contentType, setContentType] = useState(!!detail ? 'block' : 'text');

    useEffect(() => {
        setBlockHeight(detail.height);
    }, [detail])

    useEffect(() => {}, [index, publishedStatus])

    return (
        <BlockCover 
            key={index} 
            blockHeight={blockHeight}
            onDragLeave={onDragEnd}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onDragEnter={onDragEnter}
            styling={detail.styling}
            onClick={() => setActivateElement(index)}
        >

            { 
                contentType === 'block' && (
                    detail.subBlocks.map((block, subBlockIndex) => {
                
                        return (
                            <BlockElement 
                                direction={'vert'}
                                blockWidth={block.width + "%"}
                                styling={ block.styles.map((val) => `${val.name}: ${val.value}`).join(';') }
                                onClick={() => {
                                    if (!publishedStatus) {
                                        setActiveSubBlockElement(subBlockIndex);
                                    }
                                }}
                                published={publishedStatus}
                                key={index}
                                >
                                
                                    {
                                        !publishedStatus && (
                                            <>
                                            
                                                <OuterBlockTop currentDragOver={firstCurrentDragOver} />
                                                <OuterBlockBottom currentDragOver={currentDragOver} />
                                                <BoundaryWalls 
                                                    addDrop={addDrop} 
                                                    elementActive={(activeSubBlockElement === subBlockIndex) && (elementActive)}
                                                    activeElement={index}
                                                    activeSubBlockElement={subBlockIndex}
                                                />
                                            
                                                <BackgroundBlock onClick={() => setActiveIndividualElement(null)} />
                                            
                                                {
                                                    ((activeSubBlockElement === subBlockIndex) && (elementActive) && blockLength > 1) && (
                                                        <ButtonTopRightEditable>
                                                            <ButtonLittlePositioned type={'edit'} onClick={() => removeSubBlockElement(index, subBlockIndex)} backgroundCol={'#FF7F7F'}>
                                                                <icons.CancelOutlinedStyledRed />
                                                            </ButtonLittlePositioned>
                                                        </ButtonTopRightEditable>
                                                    )
                                                }

                                            </>
                                        )
                                    
                                    }
                                  
                                    
                                    { 
                                
                                    block.content.map((content, individualIndex) => {
                    
                                        return (
                                            <InputElementsCombined direction={'vert'}>
                                               
                                                <MiniButton positionSet={'top: -33%;'} active={!publishedStatus} onClick={() => addDrop('up', activeElement, subBlockIndex, individualIndex)}><icons.AddStyledLightWhite /></MiniButton>
                                                <InputElementToUse
                                                    updateElement={updateElement} 
                                                    activeSubBlockElement={activeSubBlockElement} 
                                                    setActiveSubBlockElement={setActiveSubBlockElement} 
                                                    activeIndividualElement={activeIndividualElement}
                                                    setActiveIndividualElement={setActiveIndividualElement}
                                                    index={index} 
                                                    key={index}
                                                    subBlockIndex={subBlockIndex} 
                                                    individualIndex={individualIndex}
                                                    styling={content.styles} 
                                                    value={content.value}
                                                    type={content.type}
                                                    links={content.links}
                                                    publishedStatus={publishedStatus}
                                                    removeIndividualElement={removeIndividualElement}
                                                    linksAvailable={designLinks}
                                                    addLinkToDesign={addLinkToDesign}
                                                /> 
                                                <MiniButton positionSet={'bottom: -33%;'} active={!publishedStatus} onClick={() => addDrop('down', activeElement, subBlockIndex, individualIndex)}><icons.AddStyledLightWhite /></MiniButton>

                                                {
                                                    content.type !== 'none' && !publishedStatus && (
                                                        <ButtonTopRightEditable>
                                                            <ButtonLittlePositioned type={'edit'} onClick={() => removeIndividualElement(index, subBlockIndex, individualIndex)} backgroundCol={'#FF7F7F'}>
                                                                <icons.CancelOutlinedStyledRed />
                                                            </ButtonLittlePositioned>
                                                        </ButtonTopRightEditable>
                                                    )
                                                }
                                               
                                            
                                            </InputElementsCombined>
                                        
                                            
                                        
                                        )
                                    }
                                   
                                    

                                    )}
                                

                            </BlockElement>
                        )
                    })
                )
            }
        
        </BlockCover>
    )

}


const ButtonSmallWrapper = styled.div`
    width:100%;
    height:70px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
`

const HeadingComponent = styled.div`
    width:100%;
    font-size:15px;
    font-weight:bold;
    padding-bottom:10px;
    paddig-top:20px;
    display:flex;
    justify-content:space-between;
`

const AnotherOptionDisplay = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    align-content:center;
`

const ButtonSmallNew = styled.button`
    color: #ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 5px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    align-content:center;
    width:100%;
    height:100%;
    transition: all 0.5s;
    background: ${props => !props.backgroundCol ? '#e5e5e5' : props.backgroundCol};
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 0.5;

    &:hover {
        transition: all 0.3s;
        opacity:1
    }

    &:active {
        transition: all 0.3s;
        opacity:1
    }


`

const ButtonAdd = styled.button`
    color: #ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 19px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    text-align:center;
    justify-content:center;
    align-items:center;
    align-content:center;
    border-radius:5px;
    transition: all 0.5s;
    background: ${props => !props.backgroundCol ? '#e5e5e5' : props.backgroundCol};
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 1.0;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.97);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.93);
    }


`

const NonSelectedBox = styled.div`
    display:flex;
    width:100%;
    padding:10px;
    flex-direction:row;
    text-align:center;
    justify-content:center;
    align-items:center;
    align-content:center;
    border-radius:20px;
    border-bottom-left-radius: ${props => props.active ? '0px' : '20px'};
    border-bottom-right-radius: ${props => props.active ? '0px' : '20px'};
    border:1px dashed #090909;
    transition: all 0.2s;
    cursor:default;
`

const NSBText = styled.span`
    display:flex;
    flex-direction:row;
    text-align:center;
    justify-content:center;
    align-items:center;
    align-content:center;
    z-index:100;
`

const ChooseType = styled.div`
    display:${props => props.active ? 'flex' : 'none'};
    position:absolute;
    top:100%;
    padding:20px;
    border-radius:20px;
    border-top-left-radius:0px;
    border-top-right-radius:0px;
    width:100%;
    background:#ffffff;
    border:1px solid #e5e5e5;
    z-index:1000;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
`


const ButtonLittle = styled.button`
    color: #ffffff;
    box-sizing:border-box;
    font-size:13px;
    outline:0;
    margin-left:10px;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:25px;
    height:25px;
    border-radius:30px;
    transition: all 0.5s;
    background:transparent;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 1.0;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.97);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.93);
    }


`

const ButtonTopRightEditable = styled.div`
    position:absolute;
    top:5px;
    right:5px;
    display:none;
    flex-direction:row;
    index:10000;

`

const ButtonTopRightEditableOn = styled.div`
    position:absolute;
    top:15px;
    right:15px;
    display:flex;
    flex-direction:row;
    z-index:100000;
`

const MiniButton = styled.div`
    display:${props => props.active ? 'flex' : 'none'};
    color: #ffffff;
    box-sizing:border-box;
    font-size:13px;
    outline:0;
    cursor:pointer;
    flex-direction:row;
    position:absolute;
    ${props => props.positionSet}
    justify-content:center;
    align-items:center;
    align-content:center;
    width:25px;
    height:25px;
    border-radius:30px;
    transition: all 0.5s;
    background:rgb(0, 205, 112);
    border: 1px solid transparent;
    box-shadow: #a9a9a9 0px 4px 7px 0px, transparent 0px 2px 3px 0px;
    opacity: 0.0;
    z-index:100000000;
    


    &:hover {
        transition: all 0.3s;
        opacity:1.0;
    }

    &:active {
        transition: all 0.3s;
        opacity:1.0;

    }

`

const ButtonLittlePositioned = styled.button`
    color: #ffffff;
    box-sizing:border-box;
    font-size:13px;
    outline:0;
    margin-left:10px;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:25px;
    height:25px;
    border-radius:30px;
    transition: all 0.5s;
    background:transparent;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 1.0;
    z-index:100000;

    &:hover {
        transition: all 0.3s;
        transform: rotate(90deg);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.8);
    }


`



const ButtonOnRightHeading = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    margin-right:10px;
    cursor:pointer;

    &:hover {
        transition: all 0.3s;
        transform: rotate(360deg);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.8);
    }

`

const BlockCover = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:100%;
    background:#e5e5e5;
    height: ${props => props.blockHeight + 'px'};
    ${ props => props.styling };
`

const BlockElement = styled.div`
    display:flex;
    flex-direction:${props => props.direction === 'vert' ? 'column' : 'row'};
    justify-content:center;
    align-items:center;
    align-content:center;
    height:100%;
    position:relative;
    cursor: ${props => !props.published ? 'pointer' : 'all-scroll' };
    width: ${props => !!props.width ? props.blockWidth + '%' : '100%'};
    ${props => props.styling };
`

const InnerBlockLeft = styled.div`
    position:absolute;
    width:2px;
    height:100%;
    left:0;
    transition: all 0.3s;
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;

    ${props => props.active ? (
        `background: #000000;`
    ) : (`${BlockElement}:hover & {
            background: rgb(0, 146, 255); /* Change the background color on hover */
    }`)}
`


const BackgroundBlock = styled.div`
    position:absolute;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1;
`


const InnerBlockRight = styled.div`
    position:absolute;
    width:2px;
    height:100%;
    right:0;
    transition: all 0.3s;
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;

    ${props => props.active ? (
        `background: #000000;`
    ) : (`${BlockElement}:hover & {
            background: rgb(0, 146, 255); /* Change the background color on hover */
    }`)}
`

const InnerBlockTop = styled.div`
    position:absolute;
    height:2px;
    width:100%;
    top:0;
    transition: all 0.3s;
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;


    ${props => props.active ? (
        `background: #000000;`
    ) : (`${BlockElement}:hover & {
            background: rgb(0, 146, 255); /* Change the background color on hover */
    }`)}
`

const InnerBlockBottom = styled.div`
    position:absolute;
    height:2px;
    width:100%;
    bottom:0;
    transition: all 0.3s;
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;

    ${props => props.active ? (
        `background: #000000;`
    ) : (`${BlockElement}:hover & {
            background: rgb(0, 146, 255); /* Change the background color on hover */
    }`)}
`


const OuterBlockTop = styled.div`
    position:absolute;
    height:4px;
    width:100%;
    top:-2px;
    transition: all 0.3s;
    background: ${props => props.currentDragOver ? 'rgb(0, 146, 255)' : 'transparent' };
`


const OuterBlockBottom = styled.div`
    position:absolute;
    height:4px;
    width:100%;
    bottom:-2px;
    transition: all 0.3s;
    background: ${props => props.currentDragOver ? 'rgb(0, 146, 255)' : 'transparent' };
`

const LabelOverall = styled.span`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    padding:10px;
    padding-top:0px;
`

const LabelName = styled.span`
    display:flex;
    font-size:14px;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:100%;
    height:100%;
    text-align:center;

`

const LabelOtherName = styled.span`
    display:flex;
    font-size:20px;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:100%;
    height:20%;
    color: #a9a9a9;
    text-align:center;
`

const TextDisplay = styled.div`
    display:flex;
    ${ props => props.additionalStyling }
`

const AddNewStyle = styled.span`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:100%;
    padding:10px;
    padding-top:20px;
    padding-bottom:20px;
    margin-left:-5%;
`



const EmailDesigner = (props) => {

    const { designID } = props.match.params;

    const dragItem = useRef();
    const dragOverItem = useRef();

    const [currentDrag, setCurrentDrag] = useState(null);
    const [activeElement, setActiveElement] = useState(null);

    const [menuSelected, setMenuSelected] = useState('elements');
    const [activeSubBlockElement, setActiveSubBlockElement] = useState(null);
    const [activeIndividualElement, setActiveIndividualElement] = useState(null);
    const [activeFocusButton, setActiveFocusButton] = useState(() => {});
    const [publishedStatus, setPublishedStatus] = useState(false);
    const [publishedDate, setPublishedDate] = useState('');
   

    if (designID === undefined) {
        props.history.push(`/content`);
    }

    const publishCurrentDesign = () => {

        setSpinnerSwitch(true);

        const formArray = { 
            designID: designID,
        };

        const helperArray = { 
            designID: setGeneralHelper,
        }

        const resetArray = {
            designID: {
                shouldReset: false
            },
        }

        database.sendForm(
            publishDesign,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validPublishDesign,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {
                if (success) {
                    setPublishedStatus(true);
                    setPublishedDate(payload.publishedDate);
                    setSpinnerSwitch(false);
                }
            }
        );
    
    }

    const deleteCurrentDesign = () => {

        setSpinnerSwitch(true);

        const formArray = { 
            designID: designID,
        };

        const helperArray = { 
            designID: setGeneralHelper,
        }

        const resetArray = {
            designID: {
                shouldReset: false
            },
        }

        database.sendForm(
            deleteDesign,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validDeleteDesign,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {
                if (success) {
                    props.history.push(`/content`);
                    setSpinnerSwitch(false);
                }
            }
        );
    
    }

    const [activeDesign, setActiveDesign] = useState([]);
    const [designLinks, setDesignLinks] = useState([]);
    const [designVariables, setDesignVariables] = useState([]);
    const [designVariableOptions, setDesignVariableOptions] = useState([
        {
            title: "Name",
            status: false,
        },
        {
            title: "Email",
            status: false,
        },
        {
            title: "Metric - current",
            status: false,
        },
        {
            title: "Metric - prior",
            status: false,
        },
        {
            title: "Metric - abs change",
            status: false,
        },
    ]);


    useEffect(() => {

        const updatedDesignVariableOptions = designVariableOptions.map(({ title }) => {

            return {
                title: title,
                status: designVariables.find((value) => value.toLowerCase() === title.toLowerCase()) !== undefined
            }
    
        });

        setDesignVariableOptions(updatedDesignVariableOptions);
       
        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [designVariables, menuSelected])
    
    const [pageReady, setpageReady] = useState(true);   

    const [spinnerSwitch, setSpinnerSwitch] = useState(true);


    // Helper handlers

    const [generalHelper, setGeneralHelper] = useState([]);



    useEffect(() => {
        setGeneralHelper([])
     }, [])


    useEffect(() => {

        // When page is ready set to:
        // setPageReady(true);

    }, [pageReady])


    useEffect( () => {
        setpageReady(true);
    }, []) 


    const { 
        getDesign,
        updateServerDesign,
        deleteDesign,
        publishDesign,
        addLinkToDesign,
    } = bindActionCreators(actions, useDispatch());

    useEffect(() => {
        getDesign({ designID: designID }, (success, payload) => {
            if (success) {
                if (!!payload.design) {
                    
                    setActiveDesign(payload.design.blocks);
                    setPublishedStatus(payload.design.ready);
                    setPublishedDate(payload.design.publishedDate);
                    setDesignVariables(payload.design.variables);
                    setDesignLinks(payload.design.links);

                } else {
                    props.history.push(`/content`);
                }
            } else {
                props.history.push(`/content`);
            }
        })

        setSpinnerSwitch(false)
        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [designID])

    const [list, setList] = useState([
        {
            name: 'One',
            styleName: '1col',
            styleSymbol: (<>
                <Col />
            </>),
            content: {
                order: 1,
                height: 200,
                styles: [{
                    name: 'background',
                    value: '#ffffff'
                }],
                subBlocks: [
                    {
                        order: 1,
                        width: 1,
                        styles: [{
                            name: 'background',
                            value: '#e5e5e5'
                        }],
                        content: [{
                            type: "none"
                        }]
                    }
                ]
            }
        }, 
        { 
            name: 'Two',
            styleName: '2col',
            styleSymbol: (<>
                <Col />
                <Col />
            </>),
            content: {
                order: 1,
                height: 200,
                styles: [{
                    name: 'background',
                    value: '#ffffff'
                }],
                subBlocks: [
                    {
                        order: 1,
                        width: 0.5,
                        styles: [{
                            name: 'background',
                            value: '#e5e5e5'
                        }],
                        content: [{
                            type: "none"
                        }]
                    },
                    {
                        order: 2,
                        width: 0.5,
                        styles: [{
                            name: 'background',
                            value: '#e5e5e5'
                        }],
                        content: [{
                            type: "none"
                        }]
                    }
                ]
            }
        }, { 
            name: 'Three',
            styleName: '3col',
            styleSymbol: (<>
                <Col />
                <Col />
                <Col />
            </>),
            content: {
                order: 1,
                height: 200,
                styles: [{
                    name: 'background',
                    value: '#ffffff'
                }],
                subBlocks: [
                    {
                        order: 1,
                        width: 0.3333,
                        styles: [{
                            name: 'background',
                            value: '#e5e5e5'
                        }],
                        content: [{
                            type: "none"
                        }]
                    },
                    {
                        order: 2,
                        width: 0.3333,
                        styles: [{
                            name: 'background',
                            value: '#e5e5e5'
                        }],
                        content: [{
                            type: "none"
                        }]
                    },
                    {
                        order: 3,
                        width: 0.3334,
                        styles: [{
                            name: 'background',
                            value: '#e5e5e5'
                        }],
                        content: [{
                            type: "none"
                        }]
                    }
                ]
            }
        },
        { 
            name: 'Four',
            styleName: '4col',
            styleSymbol: (<>
                <Col />
                <Col />
                <Col />
                <Col />
            </>),
            content: {
                order: 1,
                height: 200,
                styles: [{
                    name: 'background',
                    value: '#ffffff'
                }],
                subBlocks: [
                    {
                        order: 1,
                        width: 0.25,
                        styles: [{
                            name: 'background',
                            value: '#e5e5e5'
                        }],
                        content: [{
                            type: "none"
                        }]
                    },
                    {
                        order: 2,
                        width: 0.25,
                        styles: [{
                            name: 'background',
                            value: '#e5e5e5'
                        }],
                        content: [{
                            type: "none"
                        }]
                    },
                    {
                        order: 3,
                        width: 0.25,
                        styles: [{
                            name: 'background',
                            value: '#e5e5e5'
                        }],
                        content: [{
                            type: "none"
                        }]
                    },
                    {
                        order: 4,
                        width: 0.25,
                        styles: [{
                            name: 'background',
                            value: '#e5e5e5'
                        }],
                        content: [{
                            type: "none"
                        }]
                    }
                ]
            }
        }]);




    const [isDragging, setIsDragging] = useState(false);



    const dragStart = (e, position) => {
        dragItem.current = position;

        const dataType = e.currentTarget.getAttribute('data-type');

        e.dataTransfer.setData('data-type', dataType)

        setActiveElement(null);
        setIsDragging(true);
    }

    const dragEnter = (e, position) => {
        e.preventDefault();
        dragOverItem.current = position;
        setCurrentDrag(position);
    }

    const dragOver = (e, position) => {
        e.preventDefault();

        const mouseY = e.clientY;
        const element = e.currentTarget;
        const elementTop = element.offsetTop;
        const elementHeight = element.offsetHeight;
        const elementMidpoint = elementTop + elementHeight / 3;
        const isMouseInTopHalf = mouseY < elementMidpoint;

        const updatePosition = isMouseInTopHalf ? position - 1 : position;


        if (dragOverItem.current !== updatePosition) {
            setCurrentDrag(updatePosition);
        }

    }

    const dragEnd = (e, position) => {
        e.preventDefault();
        dragOverItem.current = null;
        setCurrentDrag(null);
        setActiveElement(null);
    }




    const addDrop = (direction, activeE, activeSubE, activeIndE) => {

        const copyCurrentDesign = [...activeDesign];

        // BLOCKS

        const blockInsert = {
            order: 1,
            height: 200,
            styles: [{
                name: 'background',
                value: '#ffffff'
            }],
            subBlocks: [
                {
                    order: 1,
                    width: 1,
                    styles: [{
                        name: 'background',
                        value: '#e5e5e5'
                    }],
                    content: [{
                        type: "none"
                    }]
                }
            ]
        }

        // SUBBLOCK INSERT

        const subBlockInsert = {
            order: 1,
            width: 1,
            styles: [{
                name: 'background',
                value: '#e5e5e5'
            }],
            content: [{
                type: "none"
            }]
        }

        // CONTENT INSERT

        const contentInsert = {
            type: "none"
        }

        // UPDATE INDIVIDUAL

        if (activeE !== null && activeSubE !== null && activeIndE !== null) {

            // left and up are the same

            if (direction === 'left' || direction === 'up') {
                copyCurrentDesign[activeE].subBlocks[activeSubE].content.splice(activeIndE, 0, contentInsert);
            }

            // right and down are the same

            if (direction === 'right' || direction === 'down') {
                copyCurrentDesign[activeE].subBlocks[activeSubE].content.splice(activeIndE + 1, 0, contentInsert);
            }
            
        }


        // UPDATE SUB-BLOCK

        if (activeE !== null && activeSubE !== null && activeIndE === null) {

            if (direction === 'left') {
                copyCurrentDesign[activeE].subBlocks.splice(activeSubE, 0, subBlockInsert);
            }

            if (direction === 'right') {
                copyCurrentDesign[activeE].subBlocks.splice(activeSubE + 1, 0, subBlockInsert);
            }

            if (direction === 'up') {
                copyCurrentDesign.splice(activeE, 0, blockInsert);
            }

            if (direction === 'down') {
                copyCurrentDesign.splice(activeE + 1, 0, blockInsert);
            }

        }


        setActiveDesign(copyCurrentDesign);
        updateDesign(copyCurrentDesign);


    }




    const drop = (e) => {

        e.preventDefault();

        const mouseY = e.clientY;
        const element = e.currentTarget;
        const elementTop = element.offsetTop;
        const elementHeight = element.offsetHeight;
        const elementMidpoint = elementTop + elementHeight / 2;
        const isMouseInTopHalf = mouseY < elementMidpoint;

        const updatePosition = isMouseInTopHalf ? 0 : 1;

        setIsDragging(false);
        const copyCurrentDesign = [...activeDesign];

        const dataType = e.dataTransfer.getData('data-type');

        const currentListItem = list.find((val) => val.styleName === dataType );

        
        const positionSize = dragOverItem.current + updatePosition;
        copyCurrentDesign.splice(dragOverItem.current + updatePosition, 0, currentListItem.content);
        setActiveDesign(copyCurrentDesign);
        setActiveElement(positionSize);
        setActiveSubBlockElement(0);
        updateDesign(copyCurrentDesign);
    

        dragItem.current = null;
        dragOverItem.current = null;
        setCurrentDrag(null);
                
    }

    const activateElement = (index) => {    
        setActiveElement(index);
    }

    const removeElement = () => {
        const copyCurrentDesign = [...activeDesign];
        copyCurrentDesign.splice(activeElement, 1);
        setActiveDesign(copyCurrentDesign);
        setActiveElement(null);
        updateDesign(copyCurrentDesign);
    };


    const removeIndividualElement = (eIndex, sIndex, iIndex) => {

        if (eIndex === null || sIndex === null || iIndex === null) {
            return null
        }

        console.log('elemIndex', eIndex);
        console.log('subIndex' , sIndex);
        console.log('indIndex' , iIndex);
       


        const copyCurrentDesign = [...activeDesign];

        

        if (copyCurrentDesign[eIndex].subBlocks[sIndex].content.length > 1) {
            copyCurrentDesign[eIndex].subBlocks[sIndex].content.splice(iIndex, 1);
        }

        setActiveElement(null);
        setActiveSubBlockElement(null);
        setActiveIndividualElement(null);
        setActiveDesign(copyCurrentDesign);
        updateDesign(copyCurrentDesign);
    }


    const removeSubBlockElement = (eIndex, sIndex) => {

        if (eIndex === null || sIndex === null) {
            return null
        }

        const copyCurrentDesign = [...activeDesign];

        if (copyCurrentDesign[eIndex].subBlocks.length > 1) {
            copyCurrentDesign[eIndex].subBlocks.splice(sIndex, 1);
        } else {
            copyCurrentDesign.splice(eIndex, 1);
        }

        setActiveElement(null);
        setActiveSubBlockElement(null);
        setActiveIndividualElement(null);
        setActiveDesign(copyCurrentDesign);
        updateDesign(copyCurrentDesign);
    };

    const updateElement = (elementIndex, subBlockIndex, individualIndex, type, textValue, stylingValue) => {
        const copyCurrentDesign = [...activeDesign];

        if (textValue !== null && elementIndex !== null && subBlockIndex !== null && individualIndex !== null) {

            if (type !== 'links') {
                if (type !== "updateType") {
                    copyCurrentDesign[elementIndex].subBlocks[subBlockIndex].content[individualIndex].value = textValue;
                } else {
                    copyCurrentDesign[elementIndex].subBlocks[subBlockIndex].content[individualIndex] = textValue;
                }
    
            } else {
                copyCurrentDesign[elementIndex].subBlocks[subBlockIndex].content[individualIndex].links.push(textValue);
            }
        
        }

        if (stylingValue !== null && elementIndex !== null && subBlockIndex !== null) {

            if (type !== "defaults") {

                let currentStyles = [];
                
                if (individualIndex !== null) {
                    currentStyles = copyCurrentDesign[elementIndex].subBlocks[subBlockIndex].content[individualIndex].styles; 
                } else {
                    currentStyles = copyCurrentDesign[elementIndex].subBlocks[subBlockIndex].styles;
                }


                const indexToReplace = currentStyles.findIndex((val) => val.name === stylingValue.name);
            
                if (indexToReplace !== -1) {
                    currentStyles = currentStyles.splice(indexToReplace, 1);
                }

                if (stylingValue.value !== 'delete') {

                   
                    if (individualIndex !== null) {
                        copyCurrentDesign[elementIndex].subBlocks[subBlockIndex].content[individualIndex].styles.push(stylingValue);
                    } else {
                        copyCurrentDesign[elementIndex].subBlocks[subBlockIndex].styles.push(stylingValue);
                    }
                    
                } else {

                    if (individualIndex !== null) {
                        copyCurrentDesign[elementIndex].subBlocks[subBlockIndex].content[individualIndex].styles = [...currentStyles];
                    } else {
                        copyCurrentDesign[elementIndex].subBlocks[subBlockIndex].styles = [...currentStyles];
                    }
                }
            } else {
                
                if (stylingValue.name === 'height') {
                    copyCurrentDesign[elementIndex].height = stylingValue.value;
                }
                
                if (stylingValue.name === 'width') {
                    copyCurrentDesign[elementIndex].subBlocks[subBlockIndex].width = stylingValue.value;
                }

            }
            
        }
       
        setActiveDesign(copyCurrentDesign);
        updateDesign(copyCurrentDesign);
        
    };

    const updateDesign = (copyCurrentDesign) => {

        setSpinnerSwitch(true);

        const designToLoad = copyCurrentDesign.map((obj) => {
            const { _id, ...rest } = obj;
            
            const updatedSubBlocks = obj.subBlocks.map((other) => {
                const { _id, ...restObj} = other;
                return restObj;
            });

            return {
                ...rest,
                subBlocks: updatedSubBlocks
            }
        })

        const formArray = { 
            designID: designID,
            design: designToLoad,
        };

        const helperArray = { 
            designID: setGeneralHelper,
            design: setGeneralHelper, 
        }

        const resetArray = {
            designID: {
                shouldReset: false
            },
            design: {
                shouldReset: false
            },
        }

        database.sendForm(
            updateServerDesign,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validUpdateServerDesign,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {
                if (success) {
                    setActiveDesign(payload.design.blocks);
                }
            }
        );
    
    }

    const addLinkToDesignFinal = (url, callback) => {


        setSpinnerSwitch(true);

        const formArray = { 
            designID: designID,
            url: url
        };


        const helperArray = { 
            designID: setGeneralHelper,
            url: setGeneralHelper, 
        }

        const resetArray = {
            designID: {
                shouldReset: false
            },
            url: {
                shouldReset: false
            },
        }

        database.sendForm(
            addLinkToDesign,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validAddLinkToDesign,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {
                if (success) {
                    callback(success, payload);

                    setActiveDesign(payload.newDesign.blocks);
                    setPublishedStatus(payload.newDesign.ready);
                    setPublishedDate(payload.newDesign.publishedDate);
                    setDesignVariables(payload.newDesign.variables);
                    setDesignLinks(payload.newDesign.links);
                }
            }
        );

    }

    // NEW STYLE




    // const updateAvailableStylesList = () => {

    //     if (activeElement !== null && activeSubBlockElement !== null) {

    //         const stylesBeingUsed = activeDesign[activeElement].subBlocks[activeSubBlockElement].style.map((val) => val.name);
    //         const stylesAvailable = styleOptions.map((val) => val.name);
    //         const stylesToProvide = stylesAvailable.filter((val) => (stylesBeingUsed.includes(val) === false));
            
    //         setActiveStyleOptions(stylesToProvide);
    //     }

    // }


    

    const activeDesignLength = activeDesign.length;




    return (
        <elements.PageContainer
            pageReady={true}
            pageTitle={defaults.tabbar.GENERAL}
            pageRef={Redirections.ROUTE_LINK_CONTENT.partial}
            sticky={false}
            cover={false}
            menu={'focus'}
            goBackLink={Redirections.ROUTE_LINK_CONTENT.partial}
            pageFocusMenu={
                (
                    publishedStatus === false ? (
                        <>
                            <AccountGroupGeneral onClick={deleteCurrentDesign}>
                                <Name>Delete design</Name>
                            </AccountGroupGeneral>
                            <AccountGroupGeneralDone onClick={publishCurrentDesign}>
                                <icons.PublishedStyled />
                                <Name>Publish</Name>
                            </AccountGroupGeneralDone>
                        </>
                    ) : (
                        <>
                            <AccountGroupGeneral onClick={deleteCurrentDesign}>
                                <Name>Delete design</Name>
                            </AccountGroupGeneral>
                            <AccountGroupGeneralDoneAgain>
                                <icons.DoneWhiteStyled />

                                { console.log(publishedDate) }
                                <Name>Published on {moment.utc(publishedDate).tz('Australia/Sydney').format('DD MMM YY h:mm A')}</Name>
                            </AccountGroupGeneralDoneAgain>
                        </>
                    )
                )
            }
        >
                <SectionNext>
          
                    <RightSection>
                        <BoxArea>
                            <FormatBar></FormatBar>
                            <BoxSpacer>
                                <EmailContent>
                                    <TopExtra />
                                   
                                    {
                                        activeDesign.map((block, index) => {

                                            return (
                                                <EmailBlock 
                                                    key={index}
                                                    isDragging={isDragging}
                                                    detail={block}
                                                    index={index}
                                                    blockLength={activeDesignLength}
                                                    elementActive={activeElement === index}
                                                    activeSubBlockElement={activeSubBlockElement}
                                                    activeIndividualElement={activeIndividualElement}
                                                    setActivateElement={activateElement}
                                                    setActiveSubBlockElement={setActiveSubBlockElement}
                                                    setActiveIndividualElement={setActiveIndividualElement}
                                                    onDragEnter={(e) => dragEnter(e, index)}
                                                    onDragOver={(e) => dragOver(e, index)}
                                                    onDragEnd={(e) => dragEnd(e, index)}
                                                    currentDragOver={currentDrag === index}
                                                    firstCurrentDragOver={currentDrag === -1 && index === 0}
                                                    onDrop={(e) => drop(e)} // Call the drop function
                                                    updateElement={updateElement}
                                                    addLinkToDesign={addLinkToDesignFinal}
                                                    removeSubBlockElement={removeSubBlockElement}
                                                    removeIndividualElement={removeIndividualElement}
                                                    publishedStatus={publishedStatus}
                                                    activeElement={activeElement}
                                                    addDrop={addDrop}
                                                    designLinks={designLinks}
                                                />
                                            )

                                        })
                                    }
                                </EmailContent>
                            </BoxSpacer>
                        </BoxArea>
                    </RightSection>


                    {
                        !publishedStatus && (
                            <LeftColumn>
                                <BoxArea>
                                    
                                    <TopArea>
                                        <ClickOptions onClick={() => setMenuSelected('elements')} active={menuSelected === 'elements'}>
                                            <icons.ViewComfyRoundedStyled />
                                            <ClickOptionsText>Elements</ClickOptionsText>
                                        </ClickOptions>
                                        <ClickOptions onClick={() => setMenuSelected('variables')} active={menuSelected === 'variables'}>
                                            <icons.AlternateEmailStyled />
                                            <ClickOptionsText>Variables</ClickOptionsText>
                                        </ClickOptions>
                                        <ClickOptions onClick={() => setMenuSelected('links')} active={menuSelected === 'links'}>
                                            <icons.LaunchStyled />
                                            <ClickOptionsText>Links</ClickOptionsText>
                                        </ClickOptions>
                                    </TopArea>

                                    <BottomArea>

                                    
                                        
                                        {(menuSelected === 'elements') && (activeElement === null ? (
                                            <>
                                                <TopHeading>Add Section</TopHeading>
                                            
                                                <ListOfOptionsGroup>

                                                    {
                                                        list.map((item, index) => {

                                                            return (
                                                                <OptionToDragOuter key={index}>
                                                                    <OptionToDrag 
                                                                        onDragStart={(e) => dragStart(e, index)} 
                                                                        dragIcon={isDragging}
                                                                        draggable="true"
                                                                        data-type={item.styleName}
                                                                        >
                                                                            <OptionTop>{item.styleSymbol}</OptionTop>
                                                                            <OptionBottom>{item.name}</OptionBottom>
                                                                    </OptionToDrag>
                                                                </OptionToDragOuter>
                                                            )

                                                        })
                                                    }

                                                </ListOfOptionsGroup>
                                            </>
                                        ) : (
                                            <>
                                                <MenuBoxInner>
                                                    <MenuBoxInnerSymbolOther  onClick={() => {
                                                        setActiveElement(null);
                                                        setActiveSubBlockElement(null);
                                                        setActiveIndividualElement(null);
                                                    }}>
                                                        <icons.ChevronLeftStyled />
                                                    </MenuBoxInnerSymbolOther>
                                                    Back to main
                                                </MenuBoxInner>
                                                <ListOfOptions
                                                    updateElement={updateElement} 
                                                    activeElement={activeElement} 
                                                    activeSubBlockElement={activeSubBlockElement}
                                                    activeIndividualElement={activeIndividualElement}
                                                    activeDesign={activeDesign}
                                                />
                                            </>
                                        ))}

                                        {(menuSelected === 'variables') && (
                                            
                                            <>
                                               
                                               <TopHeading>Variables in use</TopHeading>

                                               <ListOfOptionsGroup>
                                                
                                                {designVariableOptions.map(({ title, status }) => {

                                                        return (
                                                            <AudianceListOuter>
                                                                <AudianceList>
                                                                    <icons.BlurOnStyled />
                                                                    <AudianceName>{title}</AudianceName>
                                                                    <InUse active={status}>
                                                                        { status ? (
                                                                            <icons.LinkNewStyled />         
                                                                        ) : (
                                                                            <icons.LinkNewOffStyled />    
                                                                        )}
                                                                        
                                                                    </InUse>
                                                                </AudianceList>
                                                            </AudianceListOuter>
                                                        )

                                                    })}

                                                </ListOfOptionsGroup>


                                            </>
                                        
                                        )}

                                        {(menuSelected === 'links') && (
                                            
                                            <>
                                               
                                               <TopHeading>Links in use</TopHeading>

                                               <ListOfOptionsGroup>
                                                
                                                {
                                                    designLinks.length === 0 && (
                                                        <ClickOptionsTextOther>There are no links in this template</ClickOptionsTextOther>
                                                    )
                                                }

                                                { designLinks.map(({address}) => {

                                                        return (
                                                            <AudianceListOuter>
                                                                <AudianceListOther>
                                                                    <AudianceListBottom>
                                                                        {address}
                                                                    </AudianceListBottom>
                                                                </AudianceListOther>

                                                                <ButtonTopRightEditableOn>
                                                                    <ButtonLittlePositioned type={'edit'} onClick={() => {}} backgroundCol={'#FF7F7F'}>
                                                                        <icons.CancelOutlinedStyledRed />
                                                                    </ButtonLittlePositioned>
                                                                </ButtonTopRightEditableOn>
                                                            </AudianceListOuter>
                                                        )

                                                    })}

                                                </ListOfOptionsGroup>


                                            </>
                                        
                                        )}

                                    </BottomArea>
                                </BoxArea>
                            </LeftColumn>
                        )
                    }

                    

                    
                </SectionNext>

        </elements.PageContainer>
    )

}

export default EmailDesigner;

const InUse = styled.div`
    display:flex;
    flex-shrink:0;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    background: ${ props => props.active ? 'rgb(0, 205, 112)' : '#ffffff' };
    border:1px solid ${ props => props.active ? 'rgb(0, 205, 112)' : '#e5e5e5'};
    width:30px;
    height:30px;
    border-radius:25px;
    position:relative;
`

const ItemsToShow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    padding:15px 15px;
    padding-bottom:0px;
`

const UpdateFielder = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
`

const SmallText = styled.span`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    border-radius:20px;
    background:#e9e9e9;
    dispaly:block;
    padding:7px 10px;
    font-size:13px;
`


const MenuBoxInnerSymbolOther = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
    flex-grow:0;
    flex-shrink:0;
    transition: all 0.2s;
    cursor:pointer;
`



const MenuBoxInner = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;
    border-radius:10px;
    width:100%;
    padding:10px;
    text-decoration:none;

    

    &:hover > ${MenuBoxInnerSymbolOther} {
        transform: translateX(-10px);
    }

    &:active > ${MenuBoxInnerSymbolOther} {
        transform: translateX(-5px);
        transition: all 0.1s;
    }
`


const FormatBar = styled.div`
    position:absolute;
    top:0;
    padding-top:100px;
`

const TopExtra = styled.div`
    height:5px;
    width:100%;
`

const EmailContent = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    height:100%;
    width:100%;

`


const AccountGroupOther = styled(Link)`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    padding:10px;
    transition: all 0.5s;
    text-decoration:none;
    padding-left:15px;
    margin-right:10px;
    border-radius:25px;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
    transform: ${props => props.$active ? 'scale(0.95)' : 'scale(1)'};
    cursor:pointer;
    background: ${props => props.$active ? '#32a7ff' : 'transparent'};
    box-shadow: ${props => props.$active ? 'rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px' : 'transparent 0px 7px 5px 0px, transparent 0px 3px 5px 0px'};
    
    &:hover {
        background: ${props => props.$personalpage && !props.$active ? '#1d6bbc' : '#32a7ff'};
        transform: scale(0.95);
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
    }

    &:active {
        background: ${props => props.$personalpage ? '#32a7ff' : '#1d6bbc'};
        transform: scale(0.90);
        box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 10px 0px, rgba(0, 0, 0, 0.12) 0px 3px 10px 0px;
    }
    
`


const Col = styled.div`
    height:20px;
    width:12px;
    margin-left:2px;
    background:#a9a9a9;
    border-radius:5px;
`


const AccountGroupGeneral = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    transition: all 0.5s;
    text-decoration:none;

    margin-right:10px;
    border-radius:25px;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
    transform: ${props => props.$active ? 'scale(0.95)' : 'scale(1)'};
    cursor:pointer;
    background: ${props => props.$active ? '#32a7ff' : 'transparent'};
    box-shadow: ${props => props.$active ? 'rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px' : 'transparent 0px 7px 5px 0px, transparent 0px 3px 5px 0px'};
    font-weight:bold;
    opacity:0.8;

    &:hover {
        opacity:1;
    }

    &:active {
        opacity:1;
    }
    
`



const AccountGroupGeneralDone = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    padding:10px;
    transition: all 0.5s;
    text-decoration:none;
    padding-left:15px;
    margin-right:10px;
    border-radius:25px;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
    transform: ${props => props.$active ? 'scale(0.95)' : 'scale(1)'};
    cursor:pointer;
    background: #32a7ff;
    box-shadow: ${props => props.$active ? 'rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px' : 'transparent 0px 7px 5px 0px, transparent 0px 3px 5px 0px'};
    
    &:hover {
        background: ${props => props.$personalpage && !props.$active ? '#1d6bbc' : '#32a7ff'};
        transform: scale(0.95);
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
    }

    &:active {
        background: ${props => props.$personalpage ? '#32a7ff' : '#1d6bbc'};
        transform: scale(0.90);
        box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 10px 0px, rgba(0, 0, 0, 0.12) 0px 3px 10px 0px;
    }
    
`


const AccountGroupGeneralDoneAgain = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    padding:10px;
    transition: all 0.5s;
    text-decoration:none;
    padding-left:15px;
    margin-right:10px;
    border-radius:25px;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
    transform: ${props => props.$active ? 'scale(0.95)' : 'scale(1)'};
    border:1px solid #ffffff;
    box-shadow: ${props => props.$active ? 'rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px' : 'transparent 0px 7px 5px 0px, transparent 0px 3px 5px 0px'};
    
`

const Name = styled.div`
    font-size:12px;
    color:#ffffff;
    display:flex;
    padding-left:10px;
    padding-right:10px;

`

const BoxSpacer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    height:100%;
    width:100%;
    padding-top:60px;
`

const ButtonGoTo = styled.button`
    flex-grow:0;
    flex-shrink:0;
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    width:40px;
    height:40px;
    outline:0;
    margin-left:5px;
    cursor:pointer;
    transition: all 0.5s;
    background: ${props => props.active ? '#3cb598' : '#a9a9a9'};
    opacity:${props => props.active ? '1.0' : '0.5' };
    border-radius:5px;
    border: 1px solid transparent;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    font-weight:bold;
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    transition: all 0.3s;
    position:relative;
    top:0px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        top:0px;
        box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    }
`

const ProgressBox = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    height:200px;
    width:100%;
    padding:10px;
`

const InnerProgressBox = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    height:200px;
    width:100%;
    border:1px solid #a9a9a9;
`



const EditButtons = styled.span`
    flex-grow:1;
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    padding-left:20px;
`

const SectionName = styled.span`
    height:100%;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    align-content:center;

`

const BottomOptions = styled.div`
    width:100%;
    border-top:1px solid #e5e5e5;
    padding-top:20px;

`


const EachMemberNoneHorizontal = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-between;
    align-items: center;
    align-content:center;
    padding-top:20px;
    padding-bottom:10px;

`

const TopHeading = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:100%;
    height:50px;
    border-bottom:1px solid #e9e9e9;
    color:#a9a9a9;
    flex-shrink:0;

`


const BenefitTitle = styled.div`
    font-family: newFontFace, Arial, sans-serif;
    width:100%;
    height:80px;
    box-sizing:border-box;
    font-size:20px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    text-align:center;
`

const ErrorFieldOuter = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:100%;
`

const ListOfOptionsGroup = styled.div`
    display:flex;
    justify-content:center;
    align-items:flex-start;
    align-content:flex-start;
    flex-wrap:wrap;
    padding:15px;
    height:100%;
    width:100%;
    overflow:scroll;
`

const ListOfOptionsGroupOther = styled.div`
    display:flex;
    justify-content:center;
    align-items:flex-start;
    align-content:flex-start;
    flex-wrap:wrap;
    height:100%;
    width:100%;
`

const OptionToDragOuter = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    align-content:center;
    padding:5px;

`

const OptionTop = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    align-content:center;
    `

const OptionBottom = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    align-content:center;
`

const OptionBottomOther = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    align-content:center;
    font-size:12px;
`


const OptionToDrag = styled.span`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:80px;
    height:80px;
    border:1px solid #d9d9d9;
    border-radius:5px;
    cursor: grab;

    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        cursor:grabbing !important;
        border:1px solid rgb(0, 146, 255);
    }
`

const OptionToDragOther = styled.span`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:80px;
    height:80px;
    border:1px solid #d9d9d9;
    border-radius:5px;
    cursor: grab;

    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        cursor:grabbing !important;
        border:1px solid rgb(0, 146, 255);
    }
`

const ButtonGoToAnother = styled.button`
    flex-grow:0;
    flex-shrink:0;
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    height:40px;
    outline:0;
    margin-left:5px;
    cursor:pointer;
    transition: all 0.5s;
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
    background: ${props => props.active ? '#3cb598' : '#a9a9a9'};
    opacity:${props => props.active ? '1.0' : '0.5' };
    border-radius:5px;
    border: 1px solid transparent;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    font-weight:bold;
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    transition: all 0.3s;
    position:relative;
    top:0px;
    margin-top:20px;
    padding:10px 10px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        top:0px;
        box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    }
`


const AudianceList = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    padding:20px;
    border-radius:30px;
    text-align:center;
    cursor:pointer;
    transition: all 0.3s;
    background: #f5fafb;
    border:1px solid ${props => props.active ? '#9fe9ca' : '#e5e5e5'};
    width:100%;
`

const AudianceListOther = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    padding:20px;
    border-radius:10px;
    text-align:center;
    cursor:pointer;
    transition: all 0.3s;
    background: #f5fafb;
    border:1px solid ${props => props.active ? '#9fe9ca' : '#e5e5e5'};
    width:100%;
`

const AudianceListTop = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
`

const AudianceListBottom = styled.span`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    font-size:12px;
    overflow: hidden;
    white-space: nowrap;    
    text-overflow: ellipsis;
`

// #4CD964;
const Button = styled.button`
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 25px 0px;
    outline:0;
    margin-top:20px;
    cursor:pointer;
    transition: all 0.5s;
    width:100%;
    background: #3cb598;
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    
    font-weight:bold;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:focus {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`

const FieldTitleOther = styled.div`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    font-size:14px;
    padding:10px;
    font-weight:bold;
`


const AudianceListOuter = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    padding:10px;
    padding-bottom:0px;
    width:100%;
    position:relative;
`

const ButtonGoToAnotherFirst = styled.button`
    flex-grow:0;
    flex-shrink:0;
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    height:40px;
    outline:0;
    margin-left:5px;
    cursor:pointer;
    transition: all 0.5s;
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
    background: ${props => props.active ? '#3cb598' : '#a9a9a9'};
    opacity:${props => props.active ? '1.0' : '0.5' };
    border-radius:5px;
    border: 1px solid transparent;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    font-weight:bold;
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    transition: all 0.3s;
    position:relative;
    top:0px;
    padding:10px 10px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        top:0px;
        box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    }
`

const AudianceName = styled.span`
display:flex;
    font-size: 13px;
    text-align:left;
    padding-left:10px;
    width:100%;
`

const ButtonForSure = styled.button`
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: ${props => props.oftype === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


const AnotherButtonText = styled.span`
    display:flex;
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    font-size:15px;
    white-space:nowrap;
    width: 300px;
    align-items:center;
    justify-content:center;
    text-align:center;

`

const ButtonText = styled.span`
    display:flex;
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    font-size:15px;
    width:50px;
    align-items:center;
    justify-content:center;
    text-align:center;

`

const ItemsToShowOriginalRight = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    padding:30px 30px;
    padding-top:50px;
`


const RoleTypeTopLittle = styled.div`
    font-size:18px;
    text-align:left;
`

const EachMemberNoneOther = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    align-content:center;
    border-radius:20px;
    padding-top:250px;
`



const ErrorField = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    padding:10px;
    font-size:14px;
    border-radius:50px;
    list-style:none;
    border: 1px dashed #F67280;
    background: #ffecee;
    margin-bottom:20px;


    & > li {
        color: red;
        margin-left:5px;
        color:red;
        text-align:center;
        width:100%;

    }
`

const LeftColumn = styled.div`
    display:flex;
    flex-direction:row;
    height:100%;
    width:400px;
    padding-top:100px;
    padding-bottom:50px;
    padding-left:50px;
`

const BoxArea = styled.div`
    display:flex;
    flex-direction:column;
    justiy-content:flex-start;
    align-items:flex-start;
    align-content:center;
    width:100%;
    height:100%;
    background:#ffffff;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
`

const TopArea = styled.div`
    display:flex;
    flex-direction:row;
    justiy-content:center;
    align-items:center;
    align-content:center;
    width:100%;
    height:70px;
`


const ClickOptions = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    height:100%;
    width:100%;
    background: ${props => props.active ? '#ffffff' : '#e5e5e5'}; 
    font-size:13px;
    cursor:pointer;

    &:hover {
        opacity:0.8
    }
`

const ClickOptionsText = styled.span`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    font-size:10px;

`


const ClickOptionsTextOther = styled.span`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    font-size:14px;
    text-align:center;

`

const BottomArea = styled.div`
    display:flex;
    flex-direction:column;
    justiy-content:flex-start;
    align-items:flex-start;
    align-content:center;
    width:100%;
    height:100%;
    overflow:scroll;
`



const EachMember = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    align-content:center;
    padding-bottom:20px;
    border-radius:20px;
`

const EachMemberNone = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    align-content:center;
    padding-bottom:20px;
    border-radius:20px;
`


const NameOfMember = styled.div`
    font-size: 16px;
    text-align:left;
    font-weight:bold;
    padding-right:20px;
`

const RoleType = styled.div`
    font-size: 16px;
    text-align:left;
`
const GoToSign = styled.div`
    padding-right:5px;
    transition: all 0.3s;
    display:flex;
    justify-content:center;
    align-items: center;
    align-content:center;  
    cursor:pointer;
    color:rgb(0, 146, 255);


    &:hover {
        padding-right:20px;
    }
`

const Seperator = styled.div`
    width:100%;
    height: 20px;
`


const EachMemberInner = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    justify-content:space-between;
    align-items: center;
    align-content:center;
    border-radius:15px;
    padding:30px;
    background:#ffffff;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
`

const EachMemberInnerAddNew = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    align-content:center;
    height:80px;
    border-radius:15px;
    background:rgb(190, 223, 229);
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    transition: all 0.3s;
    position:relative;
    top:0px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        top:0px;
        box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    }
`

const LeftColumnInner = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
    border-radius:30px;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
`

const RightSectionForm = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    justify-content:flex-start;
    align-items:center;
    height:100%;
 
`


const InputElementsCombined = styled.div`
    display:flex;
    flex-direction:${props => props.direction === 'vert' ? 'column' : 'row'};
    text-align:center;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    position:relative;

    &:hover > ${ButtonTopRightEditable} {
        display:flex;
    }
    
`


const RightSection = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    height:100%;
    justify-content:center;
    align-items:center;
    align-content:center;
    flex-grow:1;

`

const AnotherSection = styled.div`
    width:90%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
    height:100%;
`


const SectionNext = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
    height:100%;
    padding-left:50px;
    padding-right:50px;
    
`

const FormField = styled.div`
    padding:10px 0px;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
`

const FormFieldRow = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    width:100%;
`


const FormTitles = styled.span`
    font-size:14px;
    font-weight:500;
    padding-bottom:10px;
    width:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing:border-box;

`
const FieldTitle = styled.span`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
`


const HeadingTopSection = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    width:100%;
    padding-bottom:20px;
    
`



const EachMemberLeft = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-between;
    align-items: center;
    align-content:center;
    padding-left:10px;
    
`