import React, { useEffect, useState } from 'react';
import styled from 'styled-components';



import { Link } from 'react-router-dom';

import defaults from '../../constants/defaults';

import Redirections from '../../constants/routes';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../state/actions';

import { database, format } from '../../services';
import * as evaluate from '../../validators';

import * as assets from '../../assets';

import useInput from '../../hooks/useInput';

import { elements, materials, icons, inputs } from '../../components';
import moment from 'moment';



const EachSchedule = ({aud, updateLinkage, designList, audianceID, productID, updateDesign}) => {

    const [audianceActivated, setAudianceActivated] = useState(false);
    

    const [ actualDesignLink, setActualDesignLink ] = useState('');

    const [editActive, setEditActive] = useState(false);

    const { value:designLink, bind:bindDesignLink, reset:resetDesignLink } = useInput('');
    const [designLinkHelper, setDesignLinkHelper] = useState([]);
    const [designOptions, setDesignOptions] = useState([]);

    const [generalHelper, setGeneralHelper] = useState([]);

    const [spinnerSwitch, setSpinnerSwitch] = useState(true);

    const setEditActiveStatus = (status) => {

        if (status === true & actualDesignLink === '' & designList.length > 0 ) {
            resetDesignLink(designList[0].title);
        }

        setEditActive(status);
    }

    useEffect(() => {

        if (aud.audianceLinked !== null) {
            setAudianceActivated(aud.audianceLinked)
        }


        if (aud.audianceDetails !== undefined) {

            if ('hasDesignLinked' in aud.audianceDetails) {
                if (aud.audianceDetails.hasDesignLinked) {

                    const designSelected = designList.filter((val) => val._id === aud.audianceDetails.designID).map((val) => val.title);

                    setActualDesignLink(!!designSelected ? designSelected : '');
                    resetDesignLink(!!designSelected ? designSelected : '');
                }
            }
            
        }

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aud, designList])


    useEffect(() => {

        const designOptionsToLoad = designList.map((val) => { return val.title });
        setDesignOptions(designOptionsToLoad);

    },[designList]);


    const updateEmailDesignLinked = () => {    

        const designIDArray = designList.filter((val) => val.title === designLink);

        if (designIDArray.length <= 0) {
            resetEmailDesignLinked();
            return;
        }

        const designID = designIDArray[0]._id;

        const formArray = { 
            productID: productID,
            audianceID: audianceID,
            designID: designID,
        };
    
        const helperArray = { 
            productID: setGeneralHelper, 
            audianceID: setGeneralHelper,
            designID: setGeneralHelper,
        }
    
        const resetArray = {
            productID: {
                shouldReset: false,
            },
            audianceID: {
                shouldReset: false,
            },
            designID: {
                shouldReset: false,
            }
        }
    
        database.sendForm(
            updateDesign,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validDelinkingAudianceToProduct,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {
                if (success) {
                    //
                }
            }
        );

        setEditActive(false)

    }


    const resetEmailDesignLinked = () => {


        resetDesignLink(actualDesignLink);
        setEditActive(false);
    }

    // console.log('aud', aud);
    // console.log('designList', designList);

    return (
        <ScheduleItem>

            {
                audianceActivated ? (
                    <>
                        {/* <ButtonSmallOther type={'edit'} onClick={() => {
                            updateLinkage(audianceActivated)
                            setAudianceActivated(false)
                        }}>
                            <icons.CancelOutlinedStyledRed />
                        </ButtonSmallOther> */}
                        <ButtonSmall active={audianceActivated} type={'edit'} backgroundCol={'rgb(0, 205, 112)'} onClick={() => {
                            updateLinkage(audianceActivated)
                            setAudianceActivated(false)
                        }}>
                            <icons.LinkStyled />
                        </ButtonSmall>

                    </>
                ) : (
                    <ButtonSmall active={audianceActivated} type={'edit'} onClick={() => {
                        updateLinkage(audianceActivated)
                        setAudianceActivated(true)
                    }} backgroundCol={'#e5e5e5'}>
                        <icons.LinkOffStyled />
                    </ButtonSmall>
                )
            }

            {/* {
                !!audianceActivated && (
                    <ButtonSmallOther type={'edit'} onClick={() => {
                        updateLinkage(audianceActivated)
                        setAudianceActivated(false)
                    }}>
                        <icons.CancelOutlinedStyledRed />
                    </ButtonSmallOther>
                )
            }
            
             {
                !audianceActivated ? (
                    <ButtonSmall active={audianceActivated} type={'edit'} onClick={() => {
                        updateLinkage(audianceActivated)
                        setAudianceActivated(true)
                    }} backgroundCol={'#e5e5e5'}>
                        <icons.LinkOffStyled />
                    </ButtonSmall>
                ) : (
                    <ButtonSmall active={audianceActivated} type={'edit'} backgroundCol={'rgb(0, 205, 112)'}>
                        <icons.LinkStyled />
                    </ButtonSmall>
                )
            } */}

            <ScheduleLabel fontColor={audianceActivated ? 'rgb(0, 205, 112)' : null}>{aud.name}</ScheduleLabel>


           


            {
                audianceActivated ? (
        
                    <ScheduleDate>

                        {
                            !editActive ? (
                                <>
                                    { actualDesignLink === '' ? null : (
                                        <GroupOfDesigns>
                                            <DisplayContent>{designLink}</DisplayContent>
                                            <SmallTitle>Design linked</SmallTitle>
                                        </GroupOfDesigns>
                                    )}
                                    
                                    <ButtonMini type={'edit'} onClick={() => setEditActiveStatus(true)}>
                                        <ButtonText type={'edit'}>{actualDesignLink === '' ? 'Link Design' : 'Edit' }</ButtonText>
                                    </ButtonMini>
                                </>
                            ) : (
                                <>
                                    {
                                        designList.length > 0 ? (
                                            <inputs.DropDownInput 
                                                title={`Email Design`}
                                                placeholder={``}
                                                ready={!true}
                                                type={`text`}
                                                bind={bindDesignLink} 
                                                options={designOptions}
                                                value={designLink}
                                                additionalStyling={`
                                                    background:#ffffff;
                                                    margin-right:10px;
                                                `}
                                                autoFocusOnMount={true}
                                                symbolRight={null}
                                                errorMessage={designLinkHelper}
                                                tabIndex={1}
                                            />
                                        ) : (
                                            <ButtonLink type={'submit'} to={Redirections.ROUTE_LINK_CONTENT.partial}>
                                                <icons.AddStyledWhite /><InternalText>Create first email design</InternalText>
                                            </ButtonLink>
                                        )
                                    }
                                    
                                    <ButtonMini type={'edit'} onClick={resetEmailDesignLinked}>
                                        <ButtonText type={'edit'}>Cancel</ButtonText>
                                    </ButtonMini>

                                    {
                                        designList.length > 0 && (
                                            <ButtonMini type={'submit'} onClick={updateEmailDesignLinked}>
                                                <ButtonText type={'submit'}>Save</ButtonText>
                                            </ButtonMini>
                                        )
                                        
                                    }
                                    
                                </>
                            )
                            
                        }
                         
                    </ScheduleDate>
            
                ) : null
            }
    
           
        </ScheduleItem>
    )

    
}

const DateBlocker = ({dateToShow}) => {

    return (
        <OverallDateBlocker>
            <Boundary />
            <DateOverBoundary>{dateToShow}</DateOverBoundary>
            <Boundary />
        </OverallDateBlocker>
    )

}



const EachDistributionToCreate = ({eachDistribution, index, activateModal, schedule, designList, audiances, removeDistribution, distribution }) => {

    const [editDistribution, setEditDistribution] = useState(false);
    
    return (
        <Outter key={index}>
            <EachDistributionShown>
                <FirstIndex onClick={() => setEditDistribution(!editDistribution)}>{!editDistribution ? index + 1 :  (<icons.ArrowBackStyled />)}</FirstIndex>
                <EachDistributionSection>
                    <IndividualDistElementsTitleSmaller valid={eachDistribution.designLinkedID !== undefined}>
                        A. Chose email design:
                        { eachDistribution.designLinkedID !== undefined ? null : (<icons.WarningSymbolStyled />)}

                    </IndividualDistElementsTitleSmaller>

                    <IndividualDistElementsOtherSmaller onClick={() => activateModal(schedule.scheduleLabel, index, distribution._id, eachDistribution._id, 'design', eachDistribution.designLinkedID, designList)}>
                        {
                            eachDistribution.designLinkedID !== undefined ? (
                                designList.find((val) => val._id === eachDistribution.designLinkedID).title
                            ) : (
                                'Update'
                            )
                        }
                        <icons.EditOtherBlue />
                    </IndividualDistElementsOtherSmaller>

                </EachDistributionSection>
                <EachDistributionSection>
                    <IndividualDistElementsTitleSmaller valid={eachDistribution.audianceIDs.length > 0}>
                        B. Link audiances
                        { eachDistribution.audianceIDs.length > 0 ? null : (<icons.WarningSymbolStyled />)}
                    </IndividualDistElementsTitleSmaller>
                    
                    <IndividualDistElementsOther  onClick={() => activateModal(schedule.scheduleLabel, index, distribution._id, eachDistribution._id,  'audiances', eachDistribution.audianceIDs, audiances)}>

                        {
                            eachDistribution.audianceIDs.length > 0 ? (
                                <SubList>
                    
                                    {
                                        eachDistribution.audianceIDs.length + " audiances linked"
                                    }

                                </SubList>
                            ) : (
                                'Update'
                            )

                        }
                        
                        <icons.EditOtherBlue />
                    </IndividualDistElementsOther>
                        
                </EachDistributionSection>
                <EachDistributionSection>
                    <IndividualDistElementsTitle valid={eachDistribution.conditionalResponses.length > 0}>

                        C. Pre-determine variables

                        { eachDistribution.conditionalResponses.length > 0 ? null : (<icons.WarningSymbolStyled />)}
                        
                    </IndividualDistElementsTitle>
                    
                    <IndividualDistElementsOther  onClick={() => activateModal(schedule.scheduleLabel, index, distribution._id, eachDistribution._id,  'variables', ['name'], eachDistribution.conditionalResponses)}>

                        {
                            eachDistribution.conditionalResponses.length > 0 ? (
                                <SubList>
                                    { eachDistribution.conditionalResponses.map((audiance) => {
                                        // Include eachDistributionCheck
                                        return (<SubListItem>{'ddd'}</SubListItem>)
                                    })
                                    }
                                </SubList>
                            ) : (
                                'Update'
                            )

                        }

                        <icons.EditOtherBlue />
                    </IndividualDistElementsOther>
                </EachDistributionSection>
                
            </EachDistributionShown>

            <RightRemoveButton>
                { editDistribution ? (<RemoveButton onClick={() => removeDistribution(distribution._id, eachDistribution._id, setEditDistribution)}><icons.ClearStyledRed /></RemoveButton>) : null }
            </RightRemoveButton>

        </Outter>
    )
}



const EachDataPointSchedule = ({ schedule, audiances, index, designList,  setModalContents, setShowModal }) => {

    const [generalHelper, setGeneralHelper] = useState([]);
    const [spinnerSwitch, setSpinnerSwitch] = useState(true);

    const [addEcho, setAddEcho] = useState(false);
    const { value:designLink, bind:bindDesignLink, reset:resetDesignLink } = useInput('');
    const [designLinkHelper, setDesignLinkHelper] = useState([]);
    const [designOptions, setDesignOptions] = useState([]);
    const [options, setOptions] = useState([]);


    const [activeScheduleDetails, setActiveScheduleDetails] = useState({});


    // --------

    const { value:designOption, bind:bindDesignOption, reset:resetDesignOption } = useInput('');
    const { value:audianceOption, bind:bindAudianceOption, reset:resetAudianceOption } = useInput('');

    useEffect(() => {

        if (schedule.status) {
            setActiveScheduleDetails(schedule.detail);
        }
        
    }, [schedule])

    const { 
        addDistributionToEvent,
        removeDistributionEvent,
        addDetailToDistribution
        // activateProduct,
        // linkAudianceToProduct,
        // delinkAudianceToProduct,
        // updateAudianceEmailDesign
    } = bindActionCreators(actions, useDispatch());


    useEffect(() => {

        const designOptionsToLoad = designList.map((val) => { return val.title });
        setDesignOptions(designOptionsToLoad);

        resetDesignLink(designOptionsToLoad[0])

    },[designList]);





    const cancelAddScheduledEcho = () => {
        // Clear
        resetDesignLink(resetDesignLink(designOptions[0]));
        setDesignLinkHelper([]);
        
        // Set Echo back
        setAddEcho(false);
    }
    


    const addScheduleEcho = () => {
       
        if (!designLink) return;
      
        const designToLoad = designList.find((val) => { return val.title === designLink });

        const formArray = { 
            distributionID: schedule._id, 
            designID: designToLoad._id
        };

        const helperArray = { 
            distributionID: setGeneralHelper, 
            designID: setDesignLinkHelper,
        }

        const resetArray = {
            distributionID: {
                shouldReset: false,
            },
            designID: {
                shouldReset: true,
                setterFunction: resetDesignLink,
                setterValue: resetDesignLink(designOptions[0])
           }
        }

        database.sendForm(
            addDistributionToEvent,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validAddDistributionToEvent,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {

                if (success) {
                    setActiveScheduleDetails(payload.schedule);
                    setAddEcho(false);
                }
            }
        );
    }

    const removeScheduledEcho = (distributionID, subDistributionID, setEditDistribution) => {
      
        const formArray = { 
            distributionID: distributionID, 
            subDistributionID: subDistributionID
        };

        const helperArray = { 
            distributionID: setGeneralHelper, 
            subDistributionID: setGeneralHelper,
        }

        const resetArray = {
            distributionID: {
                shouldReset: false
            },
            subDistributionID: {
                shouldReset: false
           }
        }

        database.sendForm(
            removeDistributionEvent,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validRemoveDistributionToEvent,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {

                if (success) {
                    setActiveScheduleDetails(payload.distribution);
                    setEditDistribution(false);
                    setShowModal(false);
                }
            }

        );
    }




    const updateDistributionDetail = ({distributionID, subDistributionID, designID, addAudianceIDs, removeAudianceIDs, variables, closeModal}) => {

        if (!distributionID || !subDistributionID) return;

        if (!designID && !addAudianceIDs && !removeAudianceIDs && !variables) return;


        let formArray = {
            distributionID: distributionID, 
            subDistributionID: subDistributionID,
        }

        let helperArray = {
            distributionID: setGeneralHelper, 
            subDistributionID: setGeneralHelper,
        }

        let resetArray = {
            distributionID: {
                shouldReset: false,
            },
            subDistributionID: {
                shouldReset: false,
            },
        }

        if (!!designID) {
            formArray.designID = designID;
            helperArray.designID = setGeneralHelper;
            resetArray.designID = { shouldReset: false }
        }

        if (!!addAudianceIDs) {
            formArray.addAudianceIDs = addAudianceIDs;
            helperArray.addAudianceIDs = setGeneralHelper;
            resetArray.addAudianceIDs = { shouldReset: false }
        }

        if (!!removeAudianceIDs) {
            formArray.removeAudianceIDs = removeAudianceIDs;
            helperArray.removeAudianceIDs = setGeneralHelper;
            resetArray.removeAudianceIDs = { shouldReset: false }
        }

        database.sendForm(
            addDetailToDistribution,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validAddDetailToDistribution,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {

                if (success) {
                    setActiveScheduleDetails(payload.schedule);
                    
                    if (closeModal) {
                        setShowModal(false);
                    }
                    
                }
            }
        );
    }



    const activateModal = (label, distributionIndex, distributionID, subDistributionID, type, currentID, totalList) => {

        let ready = true;
        let variables = {
                label, 
                distributionIndex, 
                distributionID, 
                subDistributionID, 
                type, 
                currentID,
                totalList
            }

        setModalContents({
            ready: ready,
            variables: variables,
            updateFunction: updateDistributionDetail
        })

        setShowModal(true);

    };

    return (

        <EachScheduleMember key={index}>
            <EachMemberInner active={false}>
                <EachMemberLeftSchedule>
                    <NameOfMember>{schedule.scheduleLabel}</NameOfMember>
                </EachMemberLeftSchedule>
                
                <BottomOptions active={true}>

                    {
                        Object.keys(activeScheduleDetails).length !== 0 ? (
                            activeScheduleDetails.enrollments.map((eachDistribution, index) => {
                                return (
                                    <EachDistributionToCreate 
                                        key={index} 
                                        distribution={activeScheduleDetails}
                                        eachDistribution={eachDistribution} 
                                        index={index} 
                                        activateModal={activateModal}
                                        schedule={schedule}
                                        designList={designList}
                                        audiances={audiances}
                                        removeDistribution={removeScheduledEcho}
                                    />
                                )
                            })
                        ) : null
                      
                    }

                    {
                        !addEcho ? (
                            <ButtonEdit typeof={'edit'} onClick={() => setAddEcho(true)}>{'Add an Echo'}</ButtonEdit>
                        ) : (
                            <>  
                                 <NameOfMemberSmall>Chose an available email design</NameOfMemberSmall>
                                 {
                                    designList.length > 0 ? (
                                        <>
                                            <inputs.DropDownInput 
                                                title={`Email Design`}
                                                placeholder={``}
                                                ready={!true}
                                                type={`text`}
                                                bind={bindDesignLink} 
                                                options={designOptions}
                                                value={designLink}
                                                additionalStyling={`
                                                    background:#ffffff;
                                                    margin-right:10px;
                                                `}
                                                autoFocusOnMount={true}
                                                symbolRight={null}
                                                errorMessage={designLinkHelper}
                                                tabIndex={1}
                                            />
                                            <ButtonArray>
                                                <ButtonEditOther typeof={'edit'} onClick={cancelAddScheduledEcho}>{'Cancel'}</ButtonEditOther>
                                                <ButtonEditOther typeof={'other'} onClick={addScheduleEcho}>{'Create'}</ButtonEditOther>
                                            </ButtonArray>
                                        </>
                                    ) : (
                                        <ButtonLink type={'submit'} to={Redirections.ROUTE_LINK_CONTENT.partial}>
                                            <icons.AddStyledWhite /><InternalText>Create a new design</InternalText>
                                        </ButtonLink>
                                    )
                                }

                               

                            </>
                        )
                    }

                </BottomOptions>
            </EachMemberInner>
        </EachScheduleMember>
    )
}


const ModalDesigned = ({ready, variables, updateFunction}) => {

    const [designHelper, setDesignHelper] = useState([]);
    const [audianceHelper, setAudianceHelper] = useState([]);

    const [designID, setDesignID] = useState(null);
    const [audianceID, setAudianceID] = useState(null);

    const { type, currentID, totalList, distributionIndex, label, distributionID, subDistributionID } = variables;
    
    let designOptionsToLoad = [];
    let currentOption = {};

    let audianceOptionsToLoad = [];
    let currentAudianceOption = {};

    if (totalList !== undefined && currentID !== undefined) {

        if (type === 'design') {
            designOptionsToLoad = totalList.filter((val) => val._id !== currentID).map((val) => { return val.title });
            currentOption = totalList.filter((val) => val._id === currentID)[0];
        }

        if (type === 'audiances') {
            audianceOptionsToLoad = totalList.filter((val) => val._id !== currentID).map((val) => { return val.name });
            currentAudianceOption = totalList.filter((val) => currentID.includes(val._id));
        }

    }


    const { value:designOption, bind:bindDesignOption, reset:resetDesignOption } = useInput(designOptionsToLoad.length > 0 ? designOptionsToLoad[0] : '');
    const { value:audianceOption, bind:bindAudianceOption, reset:resetAudianceOption } = useInput(audianceOptionsToLoad.length > 0 ? audianceOptionsToLoad[0] : '');
   


    useEffect(() => {

        if (type === 'design') {
            if (designOptionsToLoad.length > 0) {
                resetDesignOption(designOptionsToLoad[0]);
            }
        }
     
        if (type === 'audiances') {
            if (audianceOptionsToLoad.length > 0) {
                resetAudianceOption(audianceOptionsToLoad[0]);
            }
        }
        
        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalList])



    useEffect(() => {

        if (!!totalList) {

            if (type === 'design') {
                const selectedDesign = totalList.find((design) => { return design.title === designOption })

                if (!!selectedDesign) {
                    setDesignID(selectedDesign._id);
                }
            }
            
            if (type === 'audiances') {
                const selectedAudiance = totalList.find((audiance) => { return audiance.name === audianceOption })

                if (!!selectedAudiance) {
                    setAudianceID(selectedAudiance._id);
                }
            }

        }

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [designOption, audianceOption])




    if (!ready) return null;


    const updateNow = ({distributionID, subDistributionID, designID, addAudianceIDs, removeAudianceIDs, closeModal}) => {
        
        updateFunction({distributionID, subDistributionID, designID, addAudianceIDs, removeAudianceIDs, closeModal});
    
    }

   
    return (<Container>
                
                <TopSection>

                    
                    <HeadingOfProducts>Update 

                        { type === 'design' && ' Design' }
                        { type === 'audiances' && ' Audiances' }
                        { type === 'variables' && ' Variables' }

                    </HeadingOfProducts>
                    <HeadingOfProductsSmall>{`Distribution ${distributionIndex + 1} - ` + label}</HeadingOfProductsSmall>
                    

                </TopSection>


                <BottomSection>

                    { type === 'design' && (
                        <>
                            {
                                !!currentOption && (
                                    
                                    <SectionOutline type={'row'}>
                            
                                        <LeftColumnModal>
                                            <InputHeading>Current design</InputHeading>
                                        </LeftColumnModal>
        
                                        <RightColumnCenter>
                                            <InternalTextNext>{currentOption.title}</InternalTextNext>
                                        </RightColumnCenter>
        
                                    </SectionOutline>
                                )
                            }
                           
                        
                            <SectionOutline type={'row'}>

                                <LeftColumnModal>
                                    <InputHeading>Change to</InputHeading>
                                </LeftColumnModal>
                            
                                {/* <LeftColumnModal>
                                    <InputHeading>Replace with</InputHeading>
                                    <InputSubheading>This is the email template you would like to use</InputSubheading>
                                </LeftColumnModal> */}

                                <RightColumn>
                                    {
                                        designOptionsToLoad.length > 0 ? (
                                            <>
                                                <inputs.DropDownInput 
                                                    title={`Email Design`}
                                                    placeholder={``}
                                                    ready={!true}
                                                    type={`text`}
                                                    bind={bindDesignOption} 
                                                    options={designOptionsToLoad}  
                                                    value={designOption}
                                                    additionalStyling={`
                                                        background:#ffffff;
                                                        margin-right:10px;
                                                        text-align:center;
                                                    `}
                                                    autoFocusOnMount={true}
                                                    symbolRight={null}
                                                    errorMessage={designHelper}
                                                    tabIndex={1}
                                                />
                                                <VariablesList></VariablesList>
                                            </>
                                        ) : (
                                            <ButtonLink type={'submit'} to={Redirections.ROUTE_LINK_CONTENT.partial}>
                                                <icons.AddStyledWhite /><InternalText>Create a new design</InternalText>
                                            </ButtonLink>
                                        )
                                    }
                                </RightColumn>

                            </SectionOutline>
                         </>
                    )}


                    { type === 'audiances' && (
                       
                        <>
                            
                            <SectionOutline type={'row'}>
                    
                                <LeftColumnModal>
                                    <InputHeading>Current audiances</InputHeading>
                                </LeftColumnModal>

                                <RightColumnCenterOther>
                                    
                                        {
                                            currentAudianceOption.map((val) => {
                                                return (
                                                    <EachAudianceOptionOuter>
                                                        <EachAudianceOption>
                                                            <EachAudianceOptionTitle>{val.name}</EachAudianceOptionTitle>
                                                            <CancelButton onClick={() => updateNow({distributionID, subDistributionID, removeAudianceIDs: [val._id], closeModal: false})}>
                                                                <icons.CancelFullStyled />
                                                            </CancelButton>
                                                        </EachAudianceOption>
                                                    </EachAudianceOptionOuter>
                                                )
                                            })
                                        }
                                      
                                </RightColumnCenterOther>

                            </SectionOutline>
                        
                            <SectionOutline type={'row'}>

                                <LeftColumnModal>
                                    <InputHeading>Add new</InputHeading>
                                </LeftColumnModal>

                                <RightColumnNew>
                                    {
                                        audianceOptionsToLoad.length > 0 ? (
                                            <>
                                                <inputs.DropDownInput 
                                                    title={`Audiance name`}
                                                    placeholder={``}
                                                    ready={!true}
                                                    type={`text`}
                                                    bind={bindAudianceOption} 
                                                    options={audianceOptionsToLoad}  
                                                    value={audianceOption}
                                                    additionalStyling={`
                                                        background:#ffffff;
                                                        margin-right:10px;
                                                        text-align:center;
                                                    `}
                                                    autoFocusOnMount={true}
                                                    symbolRight={null}
                                                    errorMessage={audianceHelper}
                                                    tabIndex={1}
                                                />
                                                <ActionButtonContainer>
                                                    <ActionButtons type={'submit'} mainType={true} onClick={() => updateNow({distributionID, subDistributionID, addAudianceIDs: [audianceID], closeModal: false})}>
                                                        <icons.AddStyledWhite />
                                                    </ActionButtons>
                                                </ActionButtonContainer>
                                                <VariablesList></VariablesList>
                                            </>
                                        ) : (
                                            <ButtonLink type={'submit'} to={Redirections.ROUTE_LINK_CONTENT.partial}>
                                                <icons.AddStyledWhite /><InternalText>Create a new design</InternalText>
                                            </ButtonLink>
                                        )
                                    }
                                </RightColumnNew>

                            </SectionOutline>
                         </>
                    )}
                        

                </BottomSection>

        </Container>
    )

}


const EachDataPoint = ({ product, audiances, index }) => {

    const [generalHelper, setGeneralHelper] = useState([]);
    const [spinnerSwitch, setSpinnerSwitch] = useState(true);

    const { value:item, bind:bindItem, reset:resetItem } = useInput(``);
    const [itemHelper, setItemHelper] = useState([]);
    
    const [enrolmentActive, setEnrolmentActive] = useState(product.activeEnrolment);
    const [audiancesEnrolled, setAudiancesEnrolled] = useState(product.audiancesEnrolled);
    const [mappedAudiances, setMappedAudiances] = useState(audiances);

    const [designList, setDesignList] = useState([]);

    const { 
        getEmailDesigns,
        activateProduct,
        linkAudianceToProduct,
        delinkAudianceToProduct,
        updateAudianceEmailDesign
    } = bindActionCreators(actions, useDispatch());

    useEffect(() => {
        getEmailDesigns({}, (success, payload) => {
            if (success) {
                setDesignList(payload.designs);
            }
        })

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {

        const mappedAudiances = audiances.map((val) => {

            const mappedResult = {
                ...val,
                audianceLinked: false
            }

            if (!!audiancesEnrolled) {
                if (audiancesEnrolled.length > 0) {

                    const foundAudiance = audiancesEnrolled.find((other) => other.audianceID === val._id );
       
                    mappedResult.audianceLinked = !!foundAudiance;

                    mappedResult.audianceDetails = foundAudiance;
                }
            }
           

            return mappedResult;
            
        })

        setMappedAudiances(mappedAudiances);

    }, [enrolmentActive, audiancesEnrolled])


    const createLinkage = (audianceOnObject) => {

        const formArray = { 
            productID: product._id, 
            audianceID: audianceOnObject
        };

        const helperArray = { 
            productID: setGeneralHelper, 
            audianceID: setGeneralHelper,
        }

        const resetArray = {
            productID: {
                shouldReset: false,
            },
            audianceID: {
                shouldReset: false,
           }
        }

        database.sendForm(
            linkAudianceToProduct,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validLinkingAudianceToProduct,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {

                if (success) {
                    setAudiancesEnrolled(payload.productEnrolmentDetails);
                }
            }
        );
    
   }

   const createDelinkage = (audianceOnObject) => {

        const formArray = { 
            productID: product._id, 
            audianceID: audianceOnObject
        };

        const helperArray = { 
            productID: setGeneralHelper, 
            audianceID: setGeneralHelper,
        }

        const resetArray = {
            productID: {
                shouldReset: false,
            },
            audianceID: {
                shouldReset: false,
        }
        }

        database.sendForm(
            delinkAudianceToProduct,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validDelinkingAudianceToProduct,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {
                if (success) {
                    setAudiancesEnrolled(payload.productEnrolmentDetails);
                }
            }
        );

    }

    const subscribeToDataPoint = (productID, active, callback) => {

        const formArray = { 
            productID, 
            active
        };

        const helperArray = { 
            productID: setGeneralHelper, 
            active: setGeneralHelper,
        }

        const resetArray = {
            productID: {
                shouldReset: false,
            },
            active: {
               shouldReset: false,
           }
        }

        database.sendForm(
            activateProduct,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validActivateProduct,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {
                if (success) {
                    callback()
                }
            }
        );
    
   }

    return (
        <EachMember key={index}>
            <EachMemberInner active={false}>
                <HeadingTopSection active={enrolmentActive}>
                    <inputs.Switch 
                        active={enrolmentActive} 
                        onClick={() => subscribeToDataPoint(product._id, !enrolmentActive, () => {
                            setEnrolmentActive(!enrolmentActive)
                        })}
                    />
                    <EachMemberLeft>
                        <NameOfMember>{product.title}</NameOfMember>
                        <SmallText>{`Subscribe a customer list to ${product.frequency.number} email${product.frequency.number > 1 ? 's' : ''} per ${product.frequency.period.toLowerCase()}` }</SmallText>
                    </EachMemberLeft>
                    

                </HeadingTopSection>

                
                {/* <BottomOptions active={enrolmentActive}>

                    <FieldTitle>Linked Audiances</FieldTitle>
                   
                    {
                        mappedAudiances === 0 && (
                            <ButtonLink type={'other'} to={Redirections.ROUTE_LINK_LISTS.partial}>
                                <icons.AddStyledWhite /><InternalText>Create first audiance list</InternalText>
                            </ButtonLink>
                        )
                    }

                    <ScheduleSection>

                        <ScheduleList>
                        { 
                            mappedAudiances.map((aud, index) => {
                                return (
                                    <EachSchedule
                                        key={index} 
                                        aud={aud} 
                                        updateLinkage={(activated) => activated ? createDelinkage(aud._id) : createLinkage(aud._id)} 
                                        designList={designList}
                                        productID={product._id}
                                        audianceID={aud._id}
                                        updateDesign={updateAudianceEmailDesign}
                                    />
                                )
                            })
                        }

                        </ScheduleList>


                    </ScheduleSection>

                </BottomOptions> */}
            </EachMemberInner>
        </EachMember>
    )
}



const DataPoints = (props) => {
    
    const [pageReady, setpageReady] = useState(true);   

    const [spinnerSwitch, setSpinnerSwitch] = useState(true);

    // Inputs
    const { value:search, bind:bindSearch, reset:resetSearch } = useInput('');

    // Helper handlers

    const [generalHelper, setGeneralHelper] = useState([]);

    const [searchHelper, setSearchHelper] = useState([]);

    const [upcomingReleasesActive, setUpcomingReleasesActive] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalContents, setModalContents] = useState({
        ready: false,
    });

    useEffect(() => {
        setGeneralHelper([])
        setSearchHelper([])
     }, [search])

    const [productList, setProductList] = useState([]);
    const [audianceList, setAudianceList] = useState([]);
    const [upcomingSchedule, setUpcomingSchedule] = useState([]);
    const [designList, setDesignList] = useState([]);
    

    useEffect(() => {

        // When page is ready set to:
        // setPageReady(true);

    }, [pageReady])


    useEffect( () => {
        setpageReady(true);
    }, []) 





    const { 
        getProductList,
        getUpcomingSchedule,
        getAudiances,
        getEmailDesigns
    } = bindActionCreators(actions, useDispatch());


    useEffect(() => {
        getProductList({}, (success, payload) => {
            if (success) {
                setProductList(payload.productList);
            }
        });
        getAudiances({}, (success, payload) => {
            if (success) {
                setAudianceList(payload.audianceList);
            }
        });
        getUpcomingSchedule({}, (success, payload) => {
            if (success) {
                setUpcomingSchedule(payload.schedules);
            }
        });
        getEmailDesigns({}, (success, payload) => {
            if (success) {
                setDesignList(payload.designs);
            }
        });
        setSpinnerSwitch(false)

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <elements.PageContainer 
            pageReady={true}
            pageTitle={defaults.tabbar.GENERAL}
            pageRef={Redirections.ROUTE_LINK_HOME_INTERNAL.partial}
            sticky={false}
            cover={false}
            menu={'access'}
        >

                <elements.Modal 
                    showModal={showModal}
                    setShowModal={setShowModal}
                >
                    <ModalDesigned 
                        ready={modalContents.ready} 
                        variables={!modalContents.variables ? {} : modalContents.variables} 
                        updateFunction={!modalContents.updateFunction ? () => {} : modalContents.updateFunction}
                    />
                   
                </elements.Modal>
    
                
                <SectionNext>
          
                        <LeftColumn>
                            
                            <BothColumns>
                                <LeftColumnInner>
                                    <BenefitTitle>Enrolled data points</BenefitTitle>
                                    {
                                        productList.length === 0 && (
                                            <EachMemberNone>
                                                <RoleType>No products exist yet</RoleType>
                                            </EachMemberNone>
                                        )
                                    }
                                    {
                                        
                                        productList.map((product, index) => {

                                            return (
                                                <EachDataPoint 
                                                    product={product} 
                                                    index={index} 
                                                    key={index} 
                                                    audiances={audianceList} 
                                                />
                                            )
                                        })
                                    }
                                    
                                </LeftColumnInner>

                                <RightColumnInner>

                                    <BothSides>
                                        <BenefitTitleSmall active={upcomingReleasesActive} onClick={() => setUpcomingReleasesActive(true)}>Upcoming releases</BenefitTitleSmall>
                                        <BenefitTitleSmall active={!upcomingReleasesActive} onClick={() => setUpcomingReleasesActive(false)}>Previous releases</BenefitTitleSmall>
                                    </BothSides>
                                    {
                                        upcomingSchedule.map((schedule, index) => {
                                            
                                            let addDate = false;

                                            if (index === 0) {
                                                addDate = true;
                                            } else if (moment.utc(upcomingSchedule[index].scheduleTime).format('MMMM yyyy') !== moment.utc(upcomingSchedule[index - 1].scheduleTime).format('MMMM yyyy')) {
                                                addDate = true;
                                            }

                                            return (
                                                <>

                                                    { addDate && (<DateBlocker dateToShow={moment.utc(schedule.scheduleTime).format('MMMM yyyy')}/>) }

                                                    <EachDataPointSchedule 
                                                        schedule={schedule} 
                                                        index={index} 
                                                        key={index} 
                                                        audiances={audianceList} 
                                                        designList={designList}
                                                        setModalContents={setModalContents}
                                                        setShowModal={setShowModal}
                                                    />

                                                </>
                                            )
                                        })

                                    }
                                    
                                </RightColumnInner>
                            </BothColumns>
                   
                        </LeftColumn>

                </SectionNext>

        </elements.PageContainer>
    )

}

export default DataPoints;



const ScheduleItem = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width:100%;
    padding:20px;
    padding-left:0px;
`

const Outter = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    align-content:center;
    width:100%;
    padding-bottom:10px;

`

const CircleIcon = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    align-content:center;
    border-radius:100px;
    height:25px;
    width:25px;
    background: ${props => props.valid ? 'rgb(0,205,122)' : 'rgb(255,127,127)'};
`

const EachDistributionShown = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    align-content:center;
    width:100%;
    height:150px;
    padding-left:20px;
    padding-right:20px;
    border:1px dashed #a9a9a9;
    border-radius:5px;
    position:relative;

`

const SubList = styled.ul`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    align-content:center;
    text-decoration:none;
    list-style-type: none;
    margin: 0;
    padding: 0; /* Optional: Remove default padding as well */
`

const SubListItem = styled.li`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    align-content:center;
    text-decoration:none;
    list-style-type: none;
    margin: 0;
    padding: 0; /* Optional: Remove default padding as well */
`

const ButtonArray = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    align-content:center;
    width:100%;
    gap:10px;
    padding-top:10px;
`


const RemoveButton = styled.button`
    border-radius:100px;
    display:flex;
    justify-content:center;
    align-items:center;
    border:none;
    outline:none;
    padding:3px;
    cursor:pointer;
    padding:5px;
    background:#ffffff;
    
    &:hover {
        background:#ebf4ff;
    }
`


const FirstIndex = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    align-content:center;
    width:40px;
    height:40px;
    position:absolute;
    left:-20px;
    border-radius:100px;
    border:1px dashed #a9a9a9;
    background:#e9e9e9;
    font-size:24px;
    cursor:pointer;

`

const VariablesList = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    align-content:center;
    padding-top:5px;
    gap:5px;
`

const EachVariableListed = styled.span`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    align-content:center;
    padding:5px;
    border-radius:20px;
    background: rgb(0, 146, 255, 0.8);
`

const EachAudianceOption = styled.span`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    align-content:center;
    padding:15px;
    border-radius:50px;
    background: rgb(0, 146, 255, 0.8);
    color:#ffffff;
`



const EachAudianceOptionTitle = styled.span`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    align-content:center;
    padding-right:10px;
    color:#ffffff;
`

const CancelButton = styled.span`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    align-content:center;
    opacity:0.5;
    transition: all 0.2s;
    cursor:pointer;

    &:hover {
        opacity:1.0;
    }
`


const EachAudianceOptionOuter = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    align-content:center;
    padding:5px;
    padding-left:0px;
    padding-bottom:0px;
`


const ScheduleLabel = styled.div`
    display:flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    width:100%;
    padding-left:20px;
`

const InternalText = styled.div`
    display:flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding-left:20px;
`

const InternalTextNext = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #a9a9a9;
`

const OverallDateBlocker = styled.div`
    padding:20px;
    display:flex;
    flex-direction:row;
    align-items:center;
    align-content:center;
    justify-content:center;
    width:100%;

`
const Boundary = styled.span`
    height:1px;
    width:100%;
    border:0.5px dashed #a9a9a9;
    display:flex;
    align-items:center;
    align-content:center;
    justify-content:center;
`
const DateOverBoundary = styled.span`
    display:flex;
    align-items:center;
    align-content:center;
    justify-content:center;
    flex-shrink:0;
    flex-grow:0;
    font-size:14px;
    color:#a9a9a9;
    padding-left:20px;
    padding-right:20px;
`


const EachDistributionSection = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    align-items:flex-start;
    align-content:flex-start;
    justify-content:flex-start;
    border-right: ${props => !props.setBorder ? 'none' : props.setBorder };
    padding:10px;
    padding-top:20px;
    height:100%;
`

const RightRemoveButton = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height:100%;

`

const DateInput = styled.input`
    
`

const TimeInput = styled.input`
    
`

const ScheduleDate = styled.div`
    display:flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    width:100%;
`

const ChoiceOfAudiance = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background:#000;
`

const IndividualDistElements = styled.span`
    display:flex;
    font-size:12px;
    padding-top:5px;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    border: 1px solid rgba(0, 146, 255, 0.2);
    color: rgb(0, 146, 255);
    width:120px;
    padding:10px;
    border-radius:5px;
    transition:all 0.3s;
    cursor:pointer;

    &:hover {
        background:#f5fafb;
    }

    &:active {
        background:#e3f1f4;
    }

    
`


const IndividualDistElementsOtherSmaller = styled.span`
    display:flex;
    font-size:12px;
    padding-top:5px;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border: 1px solid rgba(0, 146, 255, 0.2);
    color: rgb(0, 146, 255);
    width:170px;
    padding:10px;
    border-radius:5px;
    transition:all 0.3s;
    cursor:pointer;

    &:hover {
        background:#f5fafb;
    }

    &:active {
        background:#e3f1f4;
    }

    
`

const IndividualDistElementsTitleSmaller = styled.span`
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    font-size:12px;
    font-weight:bold;
    padding-bottom:10px;
    width:170px;
    color: ${props => !props.valid ? 'rgb(255, 127, 127)' : 'rgb(0, 205, 122);'};
`


const IndividualDistElementsOther = styled.span`
    display:flex;
    font-size:12px;
    padding-top:5px;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border: 1px solid rgba(0, 146, 255, 0.2);
    color: rgb(0, 146, 255);
    width:200px;
    padding:10px;
    border-radius:5px;
    transition:all 0.3s;
    cursor:pointer;

    &:hover {
        background:#f5fafb;
    }

    &:active {
        background:#e3f1f4;
    }

    
`

const IndividualDistElementsTitle = styled.span`
    display:flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size:12px;
    font-weight:bold;
    padding-bottom:10px;
    width:200px;
    color: ${props => !props.valid ? 'rgb(255, 127, 127)' : 'rgb(0, 205, 122);'};
`

const ScheduleSection = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    align-content:center;
    width:100%;
`


const Scheduleheading = styled.span`
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    text-align:left;
    font-weight:bold;
    font-size:14px;
    padding-bottom:10px;
`

const ScheduleList = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items:  flex-start;
    align-content:  flex-start;
    width:100%;
`

const BothSides = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    padding-left:20px;
    padding-right:20px;
    width:100%;
`


const ButtonEdit = styled.button`
    color: ${props => props.typeof === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    width:100%;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    transition: all 0.5s;
    background: ${props => props.typeof === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    text-align:center;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


const ButtonEditOther = styled.button`
    color: ${props => props.typeof === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    width:100%;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    transition: all 0.5s;
    background: ${props => props.typeof === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    text-align:center;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`

const BothColumns = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:flex-start;
    padding-top:50px;
    

`



const ButtonSmallOther = styled.button`
    color: #000000;
    box-sizing:border-box;
    font-size:15px;
    padding: 5px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    border-radius:50px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 1.0;
    background:transparent;
    margin-left:20px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`

const GroupOfDesigns = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    width:200px;
`

const UpdateFielderBottom = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:space-between;
    align-content:space-between;
    width:100%;

`


const ButtonMini = styled.button`
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: ${props => props.type === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


const ButtonLink = styled(Link)`
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: ${props => props.type === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    margin-right:10px;
    width:100%;
    text-decoration:none;
    justify-content:center;
    align-items:center;
    align-content:center;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


const ButtonOther = styled.button`
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
   
    width:100%;
    background: ${props => props.type === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    text-align:center;
    justify-content:center;
    align-items:center;
    align-content:center;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`

const AddDistribution = styled.div`
    border-radius:5px;
    width:100%;
    padding:20px;
    border:1px dashed #e5e5e5;


`

const DateAdded = styled.div`
    width:100%;
    border: 1px solid #000;
    border-radius:20px;
    margin-bottom:10px;
    padding:20px;
`



const EndBits = styled.span`
    color: #a9a9a9;
`
const MiddleBits = styled.span`
    padding-left:5px;
    padding-right:5px;
`







const MiniSpace = styled.div`
    width:10px;
`




const ItemsToShow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    padding:15px 15px;
    padding-bottom:0px;
`

const UpdateFielder = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
`

const SmallText = styled.span`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    border-radius:10px;
    background:#e9e9e9;
    dispaly:block;
    padding:5px 20px;
    font-size:13px;
    width:180px;
    margin-top:20px;
`

const ButtonGoTo = styled.button`
    flex-grow:0;
    flex-shrink:0;
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    width:40px;
    height:40px;
    outline:0;
    margin-left:5px;
    cursor:pointer;
    transition: all 0.5s;
    background: ${props => props.active ? '#3cb598' : '#a9a9a9'};
    opacity:${props => props.active ? '1.0' : '0.5' };
    border-radius:5px;
    border: 1px solid transparent;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    font-weight:bold;
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    transition: all 0.3s;
    position:relative;
    top:0px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        top:0px;
        box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    }
`

const AudianceName = styled.span`
    font-size: 16px;
    text-align:left;
`


const ButtonSmall = styled.button`
    color: #000000;
    box-sizing:border-box;
    font-size:15px;
    padding: 5px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.3s;
    background: ${props => !props.backgroundCol ? '#e5e5e5' : props.backgroundCol};
    border-radius:50px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 1.0;
    margin-left:10px;
    transform: rotate(0deg);

    ${props => !props.active && (`
        &:hover {
            transition: all 0.3s;
            transform: scale(0.95);
            transform: rotate(360deg);
        }

        &:active {
            transition: all 0.3s;
            transform: scale(0.9);
            transform: rotate(0deg);
        }
    `)}
`


const ProgressBox = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    height:200px;
    width:100%;
    padding:10px;
`

const InnerProgressBox = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    height:200px;
    width:100%;
    border:1px solid #a9a9a9;
`



const EditButtons = styled.span`
    flex-grow:1;
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    padding-left:20px;
`

const SectionName = styled.span`
    height:100%;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    align-content:center;

`

const BottomOptions = styled.div`
    width:100%;
    padding-top:20px;

    ${props => !props.active ? `
        height:0px;
        overflow:hidden;
        padding:0px;
        border-top:none;
    ` : null}

`




const BenefitTitle = styled.div`
    font-family: newFontFace, Arial, sans-serif;
    height:80px;
    box-sizing:border-box;
    font-size:20px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    text-align:center;
`


const BenefitTitleSmall = styled.div`
    font-family: newFontFace, Arial, sans-serif;
    height:80px;
    box-sizing:border-box;
    font-size:14px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    text-align:center;
    cursor:pointer;
    opacity:${props => props.active ? '1.0' : '0.8'};
    text-decoration: ${props => props.active ? 'underline' : 'none'};
    transition: all 0.2s;

    &:hover {
        opacity:1.0;
    }
`


const ErrorFieldOuter = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:100%;
`


// #4CD964;
const Button = styled.button`
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 25px 0px;
    outline:0;
    margin-top:20px;
    cursor:pointer;
    transition: all 0.5s;
    width:100%;
    background: #3cb598;
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    
    font-weight:bold;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:focus {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


const ButtonForSure = styled.button`
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: ${props => props.oftype === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`

const AudianceList = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding:20px;
    border-radius:5px;
    text-align:center;
    cursor:pointer;
    transition: all 0.3s;
    background: ${props => props.active ? '#9fe9ca' : '#f1f1f1' };
    border:1px solid ${props => props.active ? '#9fe9ca' : '#e5e5e5'};
    width:100%;

    &:hover {
        background: ${props => props.active ? '#5fdaa7' : '#5fdaa7' };

        & > ${ButtonSmall} {
            transform: rotate(360deg);
            background: red;
        }
    }

`

const AudianceListOuter = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    padding:5px;
    width:33.33%;
`


const AnotherButtonText = styled.span`
    display:flex;
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    font-size:15px;
    white-space:nowrap;
    width: 300px;
    align-items:center;
    justify-content:center;
    text-align:center;

`

const ButtonText = styled.span`
    display:flex;
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    font-size:15px;
    width:50px;
    align-items:center;
    justify-content:center;
    text-align:center;

`

const DisplayContent = styled.span`
    display:flex;
    color: #000000;
    font-size:15px;
    lign-items:flex-start;
    justify-content:flex-start;
    text-align:center;

`

const SmallTitle = styled.span`
    display:flex;
    color: #000000;
    font-size:10px;
    font-weight:bold;
    align-items:flex-start;
    justify-content:flex-start;
    text-align:center;

`


const ErrorField = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    padding:10px;
    font-size:14px;
    border-radius:50px;
    list-style:none;
    border: 1px dashed #F67280;
    background: #ffecee;
    margin-bottom:20px;


    & > li {
        color: red;
        margin-left:5px;
        color:red;
        text-align:center;
        width:100%;

    }
`

const LeftColumn = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
    padding-bottom:20px;
`


const EachMember = styled.div`
    display:flex;
    width:350px;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    align-content:center;
    padding-bottom:20px;
    border-radius:100px;
`

const EachScheduleMember = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    align-content:center;
    border-radius:20px;
    padding-bottom:20px;
`

const EachMemberNone = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    align-content:center;
    padding-bottom:20px;
    border-radius:20px;
`


const EachMemberLeft = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    justify-content:center;
    align-items: flex-start;
    align-content:flex-start;
    padding-left:40px;

`


const EachMemberLeftSchedule = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    justify-content:center;
    align-items: flex-start;
    align-content:flex-start;

`



const NameOfMember = styled.div`
    font-size: 16px;
    text-align:left;
    font-weight:bold;
`

const NameOfMemberSmall = styled.div`
    font-size: 14px;
    text-align:left;
    padding-bottom:5px;
    width:100%;
`

const RoleType = styled.div`
    font-size: 16px;
    text-align:left;
`
const GoToSign = styled.div`
    padding-right:5px;
    transition: all 0.3s;
    display:flex;
    justify-content:center;
    align-items: center;
    align-content:center;  
`

const Seperator = styled.div`
    width:100%;
    height: 20px;
`


const EachMemberInner = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    justify-content:space-between;
    align-items: center;
    align-content:center;
    border-radius:15px;
    padding:30px;
    background:#ffffff;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
`

const EachMemberInnerAddNew = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    align-content:center;
    height:80px;
    border-radius:15px;
    background:rgb(190, 223, 229);
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    transition: all 0.3s;
    position:relative;
    top:0px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        top:0px;
        box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    }
`

const LeftColumnInner = styled.div`
    display:flex;
    flex-direction:column;
    flex-shrink:0;
    flex-grow:0;
    width:400px;
    height:100%;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    padding-right:50px;
`

const RightColumnInner = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
    border-radius:30px;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
`

const RightSectionForm = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    justify-content:flex-start;
    align-items:center;
    height:100%;
    padding:30px;
    padding-top:0px;
`

const RightSection = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    justify-content:flex-start;
    align-items:center;
    height:100%;
    padding:30px;
`

const AnotherSection = styled.div`
    width:90%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
    height:100%;
`


const SectionNext = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
    height:100%;
    padding:50px;
    
`

const FormField = styled.div`
    padding:10px 0px;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
`

const FormFieldRow = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    width:100%;
`


const FormTitles = styled.span`
    font-size:14px;
    font-weight:500;
    padding-bottom:10px;
    width:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing:border-box;

`
const FieldTitle = styled.div`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    font-size:14px;
    padding:10px;
    font-weight:bold;
`


const HeadingTopSection = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;


    ${props => !props.active ? `
        padding:0px;
    ` : null}
    
    &:hover {
        & > ${GoToSign} {
            padding-right:20px;
        }
    }
`







/// ----


const Container = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:flex-start;
    height:100%;
    width:100%;
    position:relative;
`

const Spacer = styled.div`
    padding-top:20px;
    display:flex;
    width:100%;
    justify-content:center;
    align-items:center;
    flex-direction:column;
`


const ContinuationButton = styled.button`
    display:flex;
    justify-content:center;
    align-items:center;
    padding:15px;
    font-weight:600;
    font-size:16px;
    border-radius:10px;
    background: rgba(0,146,255,1);
    background: linear-gradient(135deg, rgba(0,146,255,1) 0%, rgba(0,212,255,1) 100%);
    color:#ffffff;
    border:none;
    cursor:pointer;
    transition: all 0.3s;
    position:relative;
    width:100%;
    height:80px;

    &:hover {
        box-shadow: rgba(60, 66, 87, 0.03) 0px 10px 10px 0px, rgba(0, 0, 0, 0.03) 0px 7px 10px 0px, inset rgba(255, 255, 255, 0.12) 0px 0px 10px 10px;
    }

    &:active {
        top:1px;
        box-shadow: rgba(60, 66, 87, 0.03) 0px 8px 10px 0px, rgba(0, 0, 0, 0.03) 0px 5px 10px 0px;
    }
`

const HeadingOfProducts = styled.span`
    display:flex;
    justify-content:flex-start
    align-items:center;
    width:100%;
    font-size:21px;
    font-weight:600;
    padding:10px;
    padding-bottom:0px;
`

const HeadingOfProductsSmall = styled.span`
    display:flex;
    justify-content:flex-start
    align-items:center;
    width:100%;
    font-size:16px;
    font-weight:400;
    padding:10px;
    padding-top:5px;
    color:#a9a9a9;
`


const TopSection = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    padding:20px 20px;
    flex-grow:0;
    flex-shrink:0;
    background:#f9f9f9;
    border-bottom:1px solid #e5e5e5;
    border-top-left-radius:25px;
    border-top-right-radius:25px;
    
`

const BottomSection = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    padding:30px;
    padding-top:20px;
    height:auto;
    max-height: 500px;
    overflow:scroll;

`

const BottomSectionEdit = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    padding:30px;
    height:auto;
    max-height: 500px;
    overflow:scroll;
    
`

const ForgotPasswordLink = styled.span`
    color:#1676F3;
    cursor:pointer;
    text-decoration:none;
    transition: all 0.3s;

    &:hover {
        color:#1664f3;
    }
`

const  SectionOutline = styled.div`
    display:flex;
    flex-direction:${props => props.type === 'row' ? 'row' : 'column' };
    justify-content:flex-start;
    align-items:flex-start;
    width:600px;
    padding-top:20px;
    flex-grow:0;
    flex-shrink:0;
`


const LeftColumnModal = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    width:230px;
    height:100%;
    flex-grow:0;
    flex-shrink:0;
    padding-right:20px;
`

const RightColumn = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
`

const RightColumnNew = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
`

const RightColumnCenter = styled.div`
    display:flex;
    flex-direction:row;
    align-content:flex-start;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    height:65px;
    border-radius:10px;
    background:#f9f9f9;
    border:1px solid #e9e9e9;
`


const RightColumnCenterOther = styled.div`
    display:flex;
    flex-direction:row;
    align-content:flex-start;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    min-height:65px;
    flex-wrap: wrap;
`



const InputHeading = styled.span`
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    font-size:16px;
    font-weight:400;
    padding-bottom:10px;
`

const InputSubheading = styled.span`
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    font-size:13px;
    font-weight:300;
    color:#595959;
    padding-bottom:20px;
`


const BottomActionBar = styled.div`
    display:flex;
    flex-direction:row
    align-items:center;
    justify-content:flex-end;
    width:100%;
    border-top:1px solid #e5e5e5;
    background:#ebf4ff;
    height:100px;
    flex-grow:0;
    flex-shrink:0;
    border-bottom-left-radius:25px;
    border-bottom-right-radius:25px;

`



const ActionButtonContainer = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;
    padding-left:10px;
    padding-right:10px;

    &:first-child {
        padding-left:50px;
    }

    &:last-child {
        padding-right:50px;
    }
`


const ActionButtons = styled.button`
    padding: 10px 20px;
    background:${props => props.mainType ? '#00cd70' : '#ffffff'};
    border-radius:5px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-direction:row;
    border:1px solid #e5e5e5;
    color:${props => props.mainType ? '#ffffff' : '#000000'};
    height:50px;
    outline:none;
    font-size:16px;
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 3px 0px, rgba(0, 0, 0, 0.03) 0px 3px 3px 0px;
    cursor:pointer;
    transition: all 0.3s;

    &:hover {
        transform: scale(0.98);
        box-shadow: rgba(60, 66, 87, 0.12) 0px 10px 10px 0px, rgba(0, 0, 0, 0.12) 0px 7px 10px 0px;
    }

    &:active {
        transform: scale(0.95);
        box-shadow: rgba(60, 66, 87, 0.12) 0px 8px 10px 0px, rgba(0, 0, 0, 0.12) 0px 5px 10px 0px;
    }
`

const InputsOrientation = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
`



const SwitchContext = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:100%;
    padding-left:50px;
`

const SwitchContextText = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    font-size:15px;
    font-weight:290;
    padding-left:10px;
    white-space:wrap;
    color: ${props => props.anon ? '#1676F3' : 'rgba(0,0,0,0.7)'};
`

const TextHere = styled.span`
    font-size:15px;
    font-weight:300;
    white-space:wrap;
`

const SelectAReview = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    height:100px;
    background:#c4deff;
    border-radius:50px;
    padding-left:10px;
    transition: all 0.5s;

    &:hover {
        background: #1676F3;
    }
`




const MenuBoxInnerSymbol = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    background: #ffffff;
    width:70px;
    height:70px;
    border-radius:50px;
    flex-grow:0;
    flex-shrink:0;
`

const MenuBoxInnerSymbolOther = styled.div`
    display:flex;
    justify-content:flex-end;
    align-items:center;
    width:50px;
    height:50px;
    border-radius:50px;
    flex-grow:0;
    flex-shrink:0;
    transition: all 0.2s;
`

const MenuBoxTitle = styled.span`
    display:flex;
    justify-content:flex-start;
    align-items:center;
    font-size:16px;
    font-weight:400;
    color:#090909;
    text-decoration:none;
    padding-left:20px;
    width:100%;

`

const MenuBoxInner = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;
    border-radius:10px;
    width:100%;
    height:100%;
    padding:10px;
    text-decoration:none;
    transition: all 0.2s;

    &:hover {
        border-radius:50px;
    }


    &:hover > ${MenuBoxInnerSymbolOther} {
        transform: translateX(-10px);
    }

    &:active > ${MenuBoxInnerSymbolOther} {
        transform: translateX(-5px);
        transition: all 0.1s;
    }
`

const AddDifferentTypeOfReview = styled.span`
    color:#1676F3;
    cursor:pointer;
    text-decoration:none;
    transition: all 0.3s;
    padding-top:25px;

    &:hover {
        color:#1664f3;
    }
    `