import validate from 'validate.js';
import * as auth from './constraints/auth';
import * as account from './constraints/account';
import * as message from './constraints/message';
import * as payments from './constraints/payments';
import * as products from './constraints/products';
import * as audiances from './constraints/audiances';
import * as designs from './constraints/designs';

import { isEmpty } from 'lodash';

export const evaluate = (formArray, constraints) => {
    return validate(formArray, constraints);
}

export const sanitizeForm = (formArray, errors, callback) => {


    // If empty error array just return the same form output as truey
    // ----------------------------------------------------------------

    if (isEmpty(errors)) {
        return callback(true, formArray);
    }


    // If not empty error array return 
    // ----------------------------------------------------------------

    let errorArray = {};

    for (const [key, value] of Object.entries(formArray)) {
        errorArray[key] = {value, error: errors[key] ? errors[key] : null}
    };  

    return callback(false, errorArray);

}


// -----------------
// AUTHENTICATION ACTIONS
export const validSignUp = auth.signup;
export const validLogin = auth.login;
export const validForgot = auth.forgot;
export const validReset = auth.reset;

// -----------------
// ACCOUNT RELATED ACTIONS
export const validUpdateAccount = account.updateAccount;


export const accountExists = account.accountExists;
export const addAddress = account.addAddress;

// -----------------
// MESSENGING ACTIONS
export const validSendMessage = message.sendMessage;

// PAYMENT WORKFLOW ACTIONS
export const validPaymentChooseAccount = payments.chooseAccount;

// PRODUCT ACTIONS
export const validActivateProduct = products.activateProduct;
export const validLinkingAudianceToProduct = products.linkAudiance;
export const validDelinkingAudianceToProduct = products.delinkAudiance;
export const validAddDistributionToEvent = products.validAddDistributionToEvent;
export const validRemoveDistributionToEvent = products.validRemoveDistributionToEvent;
export const validAddDetailToDistribution = products.validAddDetailToDistribution;


// CUSTOMER LISTS
export const validCreateAudiance = audiances.createAudiance;
export const validCheckEmail = audiances.checkEmail;
export const validAddContactsToAudiance = audiances.addContactsToAudiance;
export const validGetContactsFromAudiance = audiances.getContactsFromAudiance;
export const validRemoveContactFromAudiance = audiances.removeContactFromAudiance;

// DESIGN LISTS
export const validCreateDesign = designs.createDesign;
export const validUpdateServerDesign = designs.updateDesign;
export const validPublishDesign = designs.publishDesign;
export const validDeleteDesign = designs.deleteDesign;
export const validAddLinkToDesign = designs.addLinkToDesign;

