import React from 'react';
import styled, { keyframes } from 'styled-components';


import { Clear } from '@material-ui/icons';

const Modal = ({ children, showModal, setShowModal }) => {

    const closeAction = () => {

        setShowModal(false);

    }

    return (

        <ModalContainer show={showModal}>

        
            <SectionOne>

                <BackgroundOverlaySection onClick={() => closeAction(false)}/>

                <ModalBox>
                    { children }

                    <RemoveButton onClick={() => closeAction(false)}><ClearStyled /></RemoveButton>

                </ModalBox>

            </SectionOne>

         
        </ModalContainer>
    )

}

export default Modal;


const fade = keyframes`
    from {opacity: .2}
    to {opacity: .4}
`

const BackgroundOverlaySection = styled.div`
    position:absolute;
    height:100vh;
    width:100vw;
    top:0;
    left:0;
    background: #000000;
    z-index:2000;
    opacity: .4;
    -webkit-animation-name: ${fade};
    -webkit-animation-duration: 0.1s;
    -webkit-animation-direction: alternate;
    animation-name: ${fade};
    animation-duration: 0.1s;
    animation-direction: alternate;
`


// background: #72bcd4;
// background: linear-gradient(45deg, #72bcd4 0%, rgba(0,212,255,1) 100%);


const SectionOne = styled.div`
    height:100vh;
    display:flex;
    flex-direction:column;
    width:100vw;
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:2001;
`

const ModalContainer = styled.div`
    display:${props => props.show ? 'flex' : 'none'};
    position:absolute;
    top:0;
    left:0;
    z-index:100;
    height:100vh;
    width:100vw;
    box-sizing:border-box;
`

const grow = keyframes`
    0% {transform: scale(0.95);display:none;}
    100% {transform: scale(1.0);display:flex;}
`

const ModalBox = styled.div`
    align-items:center;
    justify-content:center;
    background:#ffffff;
    border-radius:10px;
    z-index:2002;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 10px 0px, rgba(0, 0, 0, 0.12) 0px 3px 10px 0px;
    position:relative;
    -webkit-animation-name: ${grow};
    -webkit-animation-duration: 0.2s;
    -webkit-animation-direction: alternate;
    animation-name: ${grow};
    animation-duration: 0.2s;
    animation-direction: alternate;
`

const CrossButton = styled.div`
    position:absolute;
    top:30px;
    right:30px;
    cursor:pointer;
    padding:5px;
`

const RemoveButton = styled.button`
    border-radius:100px;
    display:flex;
    justify-content:center;
    align-items:center;
    border:none;
    outline:none;
    background:transparent;
    padding:3px;
    cursor:pointer;
    position:absolute;
    top:25px;
    right:30px;
    cursor:pointer;
    padding:5px;
    background:#f9f9f9;
    
    &:hover {
        background:#ebf4ff;
    }
`

const ClearStyled = styled(Clear)`
    && {
        fill: #1676F3;
        font-size:25px;
    }
`