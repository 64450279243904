import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useInput from '../../../hooks/useInput';

import { materials, inputs } from '../../../components';

import { database } from '../../../services';


const SendAccountItem = ({ title, formName, startingValue, isLoading, action, validationConstraint, tabIndex }) => {

    useEffect(() => {
        resetItem(startingValue)

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startingValue])

    // Form inputs

    const { value:item, bind:bindItem, reset:resetItem } = useInput(startingValue);


    // Helper handlers

    const [itemHelper, setItemHelper] = useState([]);
    

    // Edit Setter 

    const [ editItem, setEditItem ] = useState(false);

    // Loading displays

    const [spinnerSwitch, setSpinnerSwitch] = useState(false);


    const handleSubmit = (e) => {

        e.preventDefault();

        const formArray = {
            [formName]: item,
        }

        const helperArray = { 
            [formName]: setItemHelper,
        }
        

        const resetArray = {
            [formName]: {
                shouldReset: true,
                setterFunction: resetItem,
                setterValue: null
            },
        }

        database.sendForm(
            action,
            formArray, 
            helperArray, 
            resetArray,
            validationConstraint, 
            setSpinnerSwitch,
            setItemHelper, // Use Item's Helper not General Helper
            (success, response) => {  
                
                console.log('here');

                if (success) {
                    console.log(success, response);
                }
            }
        );

    }

    return (
                    
        <FormField>
            <FormTitles>
                <FieldTitle>
                    { title }
                </FieldTitle>
            </FormTitles>
        
            <TransitionToColumn>

                {
                    !isLoading ? (

                            !editItem ? (
                            <>
                                <FieldValue>{ item }</FieldValue>

                                <EditButtons>

                                    <Button oftype={'edit'} onClick={() => setEditItem(true)}>
                                        <ButtonText type={'edit'}>Edit</ButtonText>
                                    </Button>

                                </EditButtons>
                            </>
                        ) : (
                            <>
                                <inputs.BasicTextInput 
                                title={{ title }}
                                placeholder={``}
                                ready={!spinnerSwitch}
                                type={`text`}
                                bind={bindItem} 
                                value={item}
                                additionalStyling={`
                                    background: #f9f9f9;
                                    width:80%;
                                `}
                                autoFocusOnMount={true}
                                symbolRight={null}
                                errorMessage={itemHelper}
                                tabIndex={tabIndex}
                                />

                                <EditButtons>

                                    <Button oftype={'edit'} onClick={() => setEditItem(false)}>
                                        <ButtonText type={'edit'}>Cancel</ButtonText>
                                    </Button>

                                    <Button type={'submit'} oftype={'save'} onClick={(e) => handleSubmit(e)}>
                                        <ButtonText type={'save'}>Save</ButtonText>
                                    </Button>

                                </EditButtons>
                            </>
                        )
                    ) : (
                        <AlignLeft>
                            <materials.Spinner color='#a9a9a9' size='small'/>
                        </AlignLeft>
                    ) 
            }


            </TransitionToColumn>
            
            
        </FormField> 

    )

}

export default SendAccountItem;



const FormField = styled.div`
    padding:10px 0px;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
`

const AlignLeft = styled.span`
    padding-left:7px;
`

const TransitionToColumn = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;


`

const FormTitles = styled.span`
    font-size:14px;
    font-weight:500;
    padding-bottom:10px;
    width:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing:border-box;

`

const FieldTitle = styled.span`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    font-weight:bold;
`


const Button = styled.button`
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: ${props => props.oftype === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`

const ButtonText = styled.span`
    display:flex;
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    font-size:15px;
    width:50px;
    align-items:center;
    justify-content:center;
    text-align:center;

`


const FieldValue = styled.span`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    width:100%;
`

const EditButtons = styled.span`
    flex-grow:1;
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    padding-left:20px;
`