export const activateProduct = {

    productID: {
        presence: true,
    },

    active: {
        presence: true,
    }

}


export const linkAudiance = {
    productID: {
        presence: true,
    },

    audianceID: {
        presence: true
    }
}

export const delinkAudiance = {
    productID: {
        presence: true,
    },

    audianceID: {
        presence: true
    }
}


export const validAddDistributionToEvent = {
   
    distributionID: {
        presence: true
    },

    designID: {
        presence: true,
    },


}

export const validAddDetailToDistribution = {
   
    distributionID: {
        presence: true
    },

    subDistributionID: {
        presence: true,
    },

}


export const validRemoveDistributionToEvent = {
   
    distributionID: {
        presence: true
    },

    subDistributionID: {
        presence: true,
    },

}


