

import { authenticate } from '../services';

import * as screens from '../screens';

import Redirections from '../constants/routes';

import * as services from '../services';

const pages = [
  
  // --------------
  // EXTERNAL

  //>>    /
  {
    pagePath: Redirections.ROUTE_LINK_HOME.partial,
    pageComponent: screens.Front,
    PageType: authenticate.PublicRoute   
  },

  //>>    /
  {
    pagePath: Redirections.ROUTE_LINK_LOGIN.partial,
    pageComponent: screens.Login,
    PageType: authenticate.PublicRoute    
  },

  //>>    /
  {
    pagePath: Redirections.ROUTE_LINK_JOIN.partial,
    pageComponent: screens.Join,
    PageType: authenticate.PublicRoute    
  },

  //>>    /
  {
    pagePath: Redirections.ROUTE_LINK_FORGOT.partial,
    pageComponent: screens.Forgot,
    PageType: authenticate.PublicRoute    
  },

  //>>    /
  {
    pagePath: Redirections.ROUTE_LINK_RESET.partial + '/:resetcode?',
    pageComponent: screens.Reset,
    PageType: authenticate.PublicRoute    
  },


  // --------------
  // INTERNAL

  //>>    /
  {
    pagePath: Redirections.ROUTE_LINK_HOME_INTERNAL.partial,
    pageComponent: screens.DataPoints,
    PageType: authenticate.PrivateRoute
  },

  //>>    /
  {
    pagePath: Redirections.ROUTE_LINK_LISTS.partial,
    pageComponent: screens.AudianceLists,
    PageType: authenticate.PrivateRoute
  },

  //>>    /content
  {
    pagePath: Redirections.ROUTE_LINK_CONTENT.partial,
    pageComponent: screens.EmailTemplates,
    PageType: authenticate.PrivateRoute
  },

  //>>    /content/
  {
    pagePath: Redirections.ROUTE_LINK_CONTENT.partial + '/:designID?',
    pageComponent: screens.EmailDesigner,
    PageType: authenticate.PrivateRoute
  },

  //>>    /account
  {
    pagePath: Redirections.ROUTE_LINK_ACCOUNT.partial + '/:sectionName?',
    pageComponent: screens.Account,
    PageType: authenticate.PrivateRoute
  },

  //>>    /messages
  // {
  //   pagePath: Redirections.ROUTE_LINK_MESSAGES.partial + '/:convoID?',
  //   pageComponent: screens.Messages,
  //   PageType: authenticate.PrivateRoute
  // },

  //>>    /image
  {
    pagePath: Redirections.ROUTE_LINK_SIGNOUT.partial,
    pageComponent: services.SignOut,
    PageType: authenticate.OpenRoute
  }

]

export default pages;
