import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import * as assets from '../../../assets';

import { app } from '../../../constants/defaults';

import { Link } from 'react-router-dom';

import Redirections from '../../../constants/routes';

import useGetImageSrc from '../../../hooks/useGetImageSrc';

import { icons, materials } from '../../../components';

const NavBar = ({startOn, goBackLink, startOnShadow, startMenuToDisplay, authenticated, pageRef, create, showModal, internal, user, activeProfile, pageFocusMenu }) => {

    const [isShadow, setShadow] = useState(startOnShadow)
    const [isSticky, setSticky] = useState(startOn);
    const [displayMenu, setDisplayMenu] = useState(startMenuToDisplay);
    const [publishOptions, setPublishOptions] = useState(pageFocusMenu);
    

    const [dropdownDisplay, setDropdownDisplay] = useState(false)

    if (pageRef !== undefined) {
        pageRef = pageRef.toLowerCase();
    } else {
        pageRef = '/'
    }

    const { name, displayPicture } = user;

    let { value: rep, ready } = useGetImageSrc(displayPicture);

    function getFirstLetters(inputString) {
        const words = inputString.split(' ');
      
        const firstLetters = words.map((word) => {
          if (word.length > 0) {
            return word[0].toUpperCase();
          } else {
            return ''; // Handle empty words if needed
          }
        });
      
        return firstLetters.join('');
      }

    const [initials, setInitials] = useState(getFirstLetters(user.name));


    useEffect(() => {
        setInitials(getFirstLetters(user.name));
    }, [user])

    // console.log(user);

    useEffect(() => {
        setPublishOptions(pageFocusMenu)
    }, [pageFocusMenu])


    useEffect(() => {
        setSticky(startOn)
        setShadow(startOnShadow)
        setDisplayMenu(startMenuToDisplay)
    }, [startOn, startOnShadow, startMenuToDisplay])

    const handleScroll = () => {

        if (window.scrollY > 80) {
            setSticky(true);
            setShadow(true);
        } else if (window.scrollY <= 80) {
            setSticky(false);
            setShadow(false);
        }

    }


    // This function handles the scroll performance issue
  const debounce = (func, wait = 5, immediate = true) => {
    let timeOut
    return () => {
      let context = this
      const later = () => {
        timeOut = null
        if (!immediate) func.apply(context)
      }
      const callNow = immediate && !timeOut
      clearTimeout(timeOut)
      timeOut = setTimeout(later, wait)
      if (callNow) func.apply(context)
    }
  }

    useEffect(() => {
        
        if (!internal) {
            window.removeEventListener("scroll", () => handleScroll);
            window.addEventListener("scroll", debounce(handleScroll));
        }

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let mainPages = false;

    if (pageRef === Redirections.ROUTE_LINK_HOME_INTERNAL.partial 
        || pageRef === Redirections.ROUTE_LINK_LISTS.partial
        || pageRef === Redirections.ROUTE_LINK_CONTENT.partial) {

            mainPages = true;

    }


    return (
        <>
        
        <NavigationBar $personalpage={!activeProfile ? 'true' : 'false'} internal={!!authenticated} sticky={isSticky} shadow={isShadow}>
           

                <LogoGroup to={displayMenu === 'focus' ? (!goBackLink ? '/' : goBackLink) : '/'}>
                        { (displayMenu === 'none' || displayMenu === 'focus')  ? ( 
                            <ArrowLeft>
                                <materials.Arrows direction={`left`} color={`#ffffff`} size={10}/>
                            </ArrowLeft>
                        ) : (
                            <LogoLeft internal={internal} sticky={isSticky} src={assets.LogoOnly} />
                        ) }
                        { (isSticky || internal) ? (<LogoName src={assets.LogoNameOnly} />) : null }
                </LogoGroup>

                
                <ButtonElement>
                    <ButtonGroup sticky={isSticky}>


                        {
                            (!authenticated && displayMenu === 'access') ? (
                                <>
                                    
                                    <Link to={`login`}><ButtonItemOther onClick={showModal} sticky={false} setBorderColor={'transparent'}>{app.TITLE_LOGIN}</ButtonItemOther></Link>
                                    <Link to={`join`}><ButtonItemOther onClick={create} sticky={/* isSticy */ true} setBorderColor={'#ffffff'}>{app.TITLE_JOIN}</ButtonItemOther></Link> 
                                    
                                </>
                            ) : null
                        }

                        {

                            (authenticated && displayMenu === 'focus') ? (
                                <ButtonCombo>

                        
                                    {
                                        publishOptions
                                    }
                                    
                                   
                                   
                                    {/* { pageFocusFunction !== null ? (
                                        <>
                                        <AccountGroupOther to={Redirections.ROUTE_LINK_CONTENT.partial} $personalpage={!activeProfile} $active={pageRef === Redirections.ROUTE_LINK_CONTENT.partial}>
                                            <icons.RepeatStyled />
                                            <Name>Save draft</Name>
                                        </AccountGroupOther>
                                        <AccountGroupGeneral onClick={pageFocusFunction}>
                                            <icons.RepeatStyled />
                                            <Name>Publish</Name>
                                        </AccountGroupGeneral>
                                        </>
                                    ) : (
                                        <AccountGroupGeneralDone>
                                            <icons.RepeatStyled />
                                            <Name>Published 14 July 2024</Name>
                                        </AccountGroupGeneralDone>
                                    )} */}
                                    
                                </ButtonCombo>

                            ) : null

                        }

                        {   
                            (authenticated && displayMenu !== 'focus') ? (
                                <>
                                    <ButtonCombo>
    
                                        <AccountGroupOther to={Redirections.ROUTE_LINK_HOME_INTERNAL.partial} $personalpage={!activeProfile} $active={pageRef === Redirections.ROUTE_LINK_HOME_INTERNAL.partial}>
                                            <icons.RepeatStyled />
                                            <Name>Echos</Name>
                                        </AccountGroupOther>

                                        <AccountGroupOther to={Redirections.ROUTE_LINK_LISTS.partial} $personalpage={!activeProfile} $active={pageRef === Redirections.ROUTE_LINK_LISTS.partial}>
                                            <icons.EmailStyled />
                                            <Name>Audiances</Name>
                                        </AccountGroupOther>                                        

                                        <AccountGroupOther to={Redirections.ROUTE_LINK_CONTENT.partial} $personalpage={!activeProfile} $active={pageRef === Redirections.ROUTE_LINK_CONTENT.partial}>
                                            <icons.BrushStyled />
                                            <Name>Content</Name>
                                        </AccountGroupOther>    

                                        <DotOuter>
                                            <Dot size={20}/>
                                        </DotOuter>
                                        


                                        <ButtonItem onClick={() => setDropdownDisplay(!dropdownDisplay)} sticky={false}>
                                        
                                            <AccountGroup addTopBottom={true} $active={dropdownDisplay || !mainPages}>
                                                
                                                <Image sourcing={rep} ready={ready}>
                                                    {initials}
                                                </Image>
                                                <Name>{ name } </Name>
                                                <materials.Arrows direction={'down'} color={'#f9f9f9'} size={7} />
                                            </AccountGroup>

                                            <NotificationPanel show={dropdownDisplay}>

                                                <MenuBoxLink to={`/account`}>
                                                    <MenuBoxInner>
                                                        <MenuBoxInnerSymbol><icons.AssistantStyled /></MenuBoxInnerSymbol>
                                                        <MenuBoxTitle>My account</MenuBoxTitle>
                                                        <MenuBoxInnerSymbolOther><icons.ChevronRightStyled /></MenuBoxInnerSymbolOther>
                                                    </MenuBoxInner>
                                                </MenuBoxLink>

                                                {/* <MenuBoxLink to={`/messages`}>
                                                    <MenuBoxInner>
                                                        <MenuBoxInnerSymbol><icons.MessageStyled /></MenuBoxInnerSymbol>
                                                        <MenuBoxTitle>Get help</MenuBoxTitle>
                                                        <MenuBoxInnerSymbolOther><icons.ChevronRightStyled /></MenuBoxInnerSymbolOther>
                                                    </MenuBoxInner>
                                                </MenuBoxLink> */}

                                                <MenuBoxLink to={Redirections.ROUTE_LINK_SIGNOUT.partial}>
                                                    <MenuBoxInner>
                                                        <MenuBoxInnerSymbol><icons.ExitToAppStyled /></MenuBoxInnerSymbol>
                                                        <MenuBoxTitle>Log off</MenuBoxTitle>
                                                        <MenuBoxInnerSymbolOther><icons.ChevronRightStyled /></MenuBoxInnerSymbolOther>
                                                    </MenuBoxInner>
                                                </MenuBoxLink>

                                            </NotificationPanel>
                                        </ButtonItem>

                                    </ButtonCombo>

                                    
                                </>
                            ) : null
                        
                        }



                        

                    </ButtonGroup>
                </ButtonElement>
           
               


        </NavigationBar>
        </>
    )
}

export default NavBar;



const NavigationBar = styled.div`
    display:flex;
    flex-grow:0;
    flex-shrink:0;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    height:${props => props.internal ? '60px' : '80px'};
    width:100%;
    position: fixed;
    background: ${props => {
        return (props.sticky || props.internal) ? (props.$personalpage === 'true' ? '#0092FF' : '#165C90') : 'transparent'}
    };
    box-shadow: ${props => {
        return props.shadow ? `rgba(60, 66, 87, 0.12) 0px 7px 10px 0px, rgba(0, 0, 0, 0.12) 0px 3px 10px 0px` : 'transparent 0px 7px 10px 0px, transparent 0px 3px 10px 0px'
    }};
    transition: all 0.5s;
    z-index:50;
`


const LogoGroup = styled(Link)`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-content:center;
    align-items:center;
    padding:0px;
    height:100%;
    box-sizing:border-box;
    z-index:10000;
`

const LogoLeft = styled.img`
    display:flex;
    flex-grow:0;
    width:${props => props.internal ? '55px' : '75px'};
    object-fill:contain;
    padding:${props => props.internal ? '5px' : '15px'};
    margin-left:${props => props.internal ? '7px' : '0px'};
    box-sizing:border-box;
    transform:${props => {
        return (props.sticky || props.internal) ? 'scale(0.7)' : 'scale(1)'}
    };
    transition: all 0.2s;
    cursor:pointer;


    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


const LogoName = styled.img`
    display:flex;
    flex-direction:row;
    object-fill:contain;
    box-sizing:border-box;
    height:20px;
    transition: all 0.2s;
    cursor:pointer;
    margin-top:3px;
    margin-left:-10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }
`






const NotificationPanel = styled.div`
    display:${props => props.show ? `flex` : `none`};
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    padding:5px;
    position:absolute;
    top: calc(100% - 5px);
    width:320px;
    min-height:50px;
    max-height:calc(100vh - 80px);
    background:#ffffff;
    box-shadow: rgba(60, 66, 87, 0.24) 0px 7px 15px 0px, rgba(0, 0, 0, 0.24) 0px 3px 15px 0px;
    right:15px;
    border-radius:10px;


`




const MenuBoxLink = styled(Link)`
    display: flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:80px;
    width:100%;
    padding:5px 10px;
    flex-grow:0;
    flex-shrink:0;
    text-decoration:none;
`



const MenuBoxInnerSymbol = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    background: #d1d1d1;
    width:45px;
    height:45px;
    border-radius:50px;
    flex-grow:0;
    flex-shrink:0;
`


const MenuBoxInnerSymbolOther = styled.div`
    display:flex;
    justify-content:flex-end;
    align-items:center;
    width:50px;
    height:50px;
    border-radius:50px;
    flex-grow:0;
    flex-shrink:0;
    transition: all 0.2s;
`

const MenuBoxTitle = styled.span`
    display:flex;
    justify-content:flex-start;
    align-items:center;
    font-size:15px;
    font-weight:400;
    color:#090909;
    text-decoration:none;
    padding-left:20px;
    width:100%;

`

const MenuBoxInner = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;
    border-radius:10px;
    width:100%;
    height:100%;
    padding:10px;
    text-decoration:none;

    &:hover {
        background:#f9f9f9;
    }

    &:hover > ${MenuBoxInnerSymbolOther} {
        transform: translateX(-10px);
    }

    &:active > ${MenuBoxInnerSymbolOther} {
        transform: translateX(-5px);
        transition: all 0.1s;
    }
`



const ButtonElement = styled.div`
    display:flex;
    flex-direction:row;
    justity-content:center;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
`

const ButtonGroup = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-content:center;
    align-items:center;
    margin-right:20px;
    box-sizing:border-box;
    z-index:10000;
`


const ButtonItem = styled.button`
    color:#ffffff;
    box-sizing:border-box;
    font-size:13px;
    padding: 10px 0px;
    margin:10px;
    outline:0;
    cursor:pointer;
    transition: all 0.5s;
    background:${props => {
        return props.sticky ? '#4CD964' : 'none'}
    };
    border-radius:5px;
    border: 1px solid transparent;

    box-shadow: ${props => {
        if (props.sticky) {
            return 'rgba(249, 249, 249, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;'
        } else {
            return 'transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;'
        }
    }};
    
    
    font-weight:bold;



`


const ButtonItemOther = styled.button`
    color:#ffffff;
    box-sizing:border-box;
    font-size:13px;
    padding: 10px 0px;
    margin:10px;
    outline:0;
    cursor:pointer;
    transition: all 0.5s;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
    background:${props => {
        return props.sticky ? '#4CD964' : 'none'}
    };
    border-radius:5px;
    border: 1px solid ${props => !!props.setBorderColor && !props.sticky ? props.setBorderColor : 'transparent'};
    width:100px;
    box-shadow: ${props => {
        if (props.sticky) {
            return 'rgba(249, 249, 249, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;'
        } else {
            return 'transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;'
        }
    }};
    
    
    font-weight:bold;


&:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }
    
    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }

`



const Image = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:35px;
    height:35px;
    border-radius:100px;
    margin-right:5px;
    box-sizing:border-box;
    border: 1px  solid #ffffff;
    box-shadow: 0px 0px 5px #4ca6ff;
    font-size:18px;
    

    ${props => props.ready ? (
        `
        background-image: url(${props.sourcing});
        background-repeat: no-repeat;
        background-size: 50px 50px; 
        background-origin: content-box;
        background-clip: content-box;
        background-position:50% 50%;
        `
    ) : (
        `background: rgb(255, 255, 255, 0.5);`
    )} 
   
    
`


const AccountGroup = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    padding:5px;
    border-radius:25px;
    transition: all 0.5s;
    box-shadow: ${props => props.$active ? 'rgba(60, 66, 87, 0.12) 0px 7px 10px 0px, rgba(0, 0, 0, 0.12) 0px 3px 10px 0px' : 'transparent 0px 7px 10px 0px, transparent 0px 3px 10px 0px'};
    transform: ${props => props.$active ? 'scale(0.95)' : 'scale(1)'};
    cursor:pointer;
    background: ${props => props.$active ? '#32a7ff' : 'transparent'};
    

    &:hover {
        background: ${props => props.$personalpage && !props.$active ? '#1d6bbc' : '#32a7ff'};
        transform: scale(0.95);
        box-shadow: ${props => props.$personalpage ? `rgba(60, 66, 87, 0.12) 0px 7px 10px 0px, rgba(0, 0, 0, 0.12) 0px 3px 10px 0px` : `transparent 0px 7px 10px 0px, transparent 0px 3px 10px 0px`};
    
    }

    &:active {
        background: ${props => props.$personalpage ? '#32a7ff' : '#1d6bbc'};
        transform: scale(0.90);
        box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 10px 0px, rgba(0, 0, 0, 0.12) 0px 3px 10px 0px;
    }
    
`



const AccountGroupOther = styled(Link)`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    padding:10px;
    transition: all 0.5s;
    text-decoration:none;
    padding-left:15px;
    margin-right:10px;
    border-radius:25px;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
    transform: ${props => props.$active ? 'scale(0.95)' : 'scale(1)'};
    cursor:pointer;
    background: ${props => props.$active ? '#32a7ff' : 'transparent'};
    box-shadow: ${props => props.$active ? 'rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px' : 'transparent 0px 7px 5px 0px, transparent 0px 3px 5px 0px'};
    
    &:hover {
        background: ${props => props.$personalpage && !props.$active ? '#1d6bbc' : '#32a7ff'};
        transform: scale(0.95);
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
    }

    &:active {
        background: ${props => props.$personalpage ? '#32a7ff' : '#1d6bbc'};
        transform: scale(0.90);
        box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 10px 0px, rgba(0, 0, 0, 0.12) 0px 3px 10px 0px;
    }
    
`


const AccountGroupGeneral = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    padding:10px;
    transition: all 0.5s;
    text-decoration:none;
    padding-left:15px;
    margin-right:10px;
    border-radius:25px;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
    transform: ${props => props.$active ? 'scale(0.95)' : 'scale(1)'};
    cursor:pointer;
    background: ${props => props.$active ? '#32a7ff' : 'transparent'};
    box-shadow: ${props => props.$active ? 'rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px' : 'transparent 0px 7px 5px 0px, transparent 0px 3px 5px 0px'};
    
    &:hover {
        background: ${props => props.$personalpage && !props.$active ? '#1d6bbc' : '#32a7ff'};
        transform: scale(0.95);
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
    }

    &:active {
        background: ${props => props.$personalpage ? '#32a7ff' : '#1d6bbc'};
        transform: scale(0.90);
        box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 10px 0px, rgba(0, 0, 0, 0.12) 0px 3px 10px 0px;
    }
    
`



const AccountGroupGeneralDone = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    padding:10px;
    transition: all 0.5s;
    text-decoration:none;
    padding-left:15px;
    margin-right:10px;
    border-radius:25px;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
    transform: ${props => props.$active ? 'scale(0.95)' : 'scale(1)'};
    cursor:pointer;
    background: #32a7ff;
    box-shadow: ${props => props.$active ? 'rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px' : 'transparent 0px 7px 5px 0px, transparent 0px 3px 5px 0px'};
    
    &:hover {
        background: ${props => props.$personalpage && !props.$active ? '#1d6bbc' : '#32a7ff'};
        transform: scale(0.95);
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
    }

    &:active {
        background: ${props => props.$personalpage ? '#32a7ff' : '#1d6bbc'};
        transform: scale(0.90);
        box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 10px 0px, rgba(0, 0, 0, 0.12) 0px 3px 10px 0px;
    }
    
`

const Name = styled.div`
    font-size:12px;
    color:#ffffff;
    display:flex;
    padding-left:10px;
    padding-right:10px;
    cursor:pointer;

`

const ButtonCombo = styled.div`
    display:flex;
    flex-direcion:row;
    flex-wrap:nowrap;
    justify-content:flex-end;
    align-items:center;
    align-content:center;
    height:100%;
`

const ArrowLeft = styled.div`
    padding-left:20px;
    padding-right:30px;
    transition: all 1s;
    cursor: pointer;

    &:hover {
        padding-right:45px;
    }
`


const DotOuter = styled.div`
    padding-left:20px;
    padding-right:20px;
`

const Dot = styled.div`
    width:1px;
    height:${props => props.size}px;
    background:#ffffff;
`

