import {
    UPDATE_STICKY,
    UPDATE_SHADOW,
    UPDATE_MENU,
    UPDATE_PAGEREF,
    UPDATE_PAGEFOCUSMENU,
    UPDATE_GOBACKLINK
}  from '../types';


export const updateSticky = (value) => async dispatch => {
    dispatch({ type: UPDATE_STICKY, value });
}

export const updateShadow = (value) => async dispatch => {
    dispatch({ type: UPDATE_SHADOW, value });
}

export const updateMenu = (value) => async dispatch => {
    dispatch({ type: UPDATE_MENU, value });
}

export const updatePageRef = (value) => async dispatch => {
    dispatch({ type: UPDATE_PAGEREF, value });
}

export const updatePageFocusMenu = (value) => async dispatch => {
    dispatch({ type: UPDATE_PAGEFOCUSMENU, value });
}

export const updateGoBackLink = (value) => async dispatch => {
    dispatch({ type: UPDATE_GOBACKLINK, value });
}
