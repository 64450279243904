import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { icons } from '../..';
import useInput from '../../../hooks/useInput';

const EmailDesignBasicTextInput = ({ 
    title, 
    placeholder, 
    errorMessage,
    ready, 
    type, 
    bind, 
    setWidth,
    additionalStyling, 
    value, 
    onBlur, 
    typeOf, 
    maxChar,
    fromRight,
    symbol: Symbol,
    tabIndex,
    setAlign,
    setTextAlign,
    activateDropdown,
    links,
    linksAvailable,
    addLinkToElement,
    addLinkToDesign,
    symbolRight: SymbolRight }) => {

    if (!onBlur) {
        onBlur = null;
    }

    if (!maxChar) {
        maxChar = null;
    }

    const finalErrorMessage = errorMessage ? errorMessage : [];

    const [ edit, setEdit ] = useState(false);

    const { value:newLinkURL, bind:bindNewLinkURL, reset:resetNewLinkURL } = useInput(``);

    const [currentLink, setCurrentLink] = useState({});

    useEffect(() => {
        
        console.log(links);
        console.log(linksAvailable);

        const link = links[0];
        const theLink = linksAvailable.find((eachLink) => eachLink._id === link);
        console.log(theLink);

        setCurrentLink(theLink);

    }, [links, linksAvailable])

    useEffect(() => {
        resetNewLinkURL('')

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit])

    const addDesignLink = () => {

        addLinkToDesign(newLinkURL, (success, payload) => {

            console.log('success: ', success);
            console.log('payload: ', payload);

            if (success) {

                addLinkToElement(payload.newLinkID);
                resetNewLinkURL('');
                setEdit(false);
            }

        });

        resetNewLinkURL('')
    }

    return (

        <>
        {
            console.log('links: ', linksAvailable)
        }
        <Container setAlign={setAlign} setWidth={setWidth} errorFormating={finalErrorMessage.length > 0}>

                {
                    !!Symbol && (
                        <ShowSymbol><Symbol /></ShowSymbol>
                    )
                }

                <InputComponent setTextAlign={setTextAlign} additionalStyling={additionalStyling} onBlur={onBlur} fromRight={!!fromRight} symbol={!!Symbol} typeOf={typeOf} placeholder={placeholder} readOnly={!ready} type={type} tabIndex={tabIndex} {...bind}/>

                <CharCounter aboveMax={!!maxChar && (value ? (value.length > maxChar) : false)} >{(!!maxChar && (value ? (value.length > maxChar) : false)) ? `Exceeding maximum characters` : `` } { (value && !!maxChar) ? (value.length > 1 ? value.length : ``) : `` }{ !!maxChar ? ` / ${maxChar}` : ``} </CharCounter>
                
                {
                    !!SymbolRight || finalErrorMessage.length > 0 ? (
                        <ShowSymbolRight>
                           
                           { !!SymbolRight && <SymbolRight /> }
                           
                           { finalErrorMessage.length > 0 && <icons.ErrorSymbol /> }

                        </ShowSymbolRight>
                    ) : null
                }

                
                <ShowDropAvailable active={activateDropdown}>
                    <TopOfShowDropDown>
                        <TextLink>Attach link</TextLink>
                        <EachElementMain active={(edit || links.length === 0)}>
                            <icons.LaunchStyled />
                            {
                                (!edit) ? (
                                    <>
                                        <InnerEachElementText>{currentLink.address }</InnerEachElementText>
                                        <icons.EditBlue onClick={() => setEdit(true)} />
                                    </>
                                ) : (
                                    <>
                                        <AddLink placeholder={'Type URL here'} {...bindNewLinkURL}/>
                                        <OuterAddButton onClick={addDesignLink}>
                                            <icons.AddStyledLightWhite/>
                                        </OuterAddButton>
                                        <ButtonLittlePositioned type={'edit'} onClick={() => setEdit(false)} backgroundCol={'#FF7F7F'}>
                                            <icons.CancelOutlinedStyledRed />
                                        </ButtonLittlePositioned>
                                    </>
                                )
                            }
                            
                        </EachElementMain>
                    </TopOfShowDropDown>
                    { edit && (
                        <BottomOfShowDropDown>

                            {
                                linksAvailable.map(({ address, id }) => {
                                    return (
                                        <EachElementOuter>
                                            <EachElement>
                                                <icons.LaunchStyled />
                                                <InnerEachElementText>{address}</InnerEachElementText>
                                            </EachElement>
                                        </EachElementOuter>
                                    )
                                })
                            }
                            
                          

                        </BottomOfShowDropDown>
                    )
                    }

                    
                </ShowDropAvailable>

        </Container>

        { finalErrorMessage.length > 0 ? (
            <ErrorFieldOuter>
                <ErrorField>

                    { 
                        finalErrorMessage.map((item) => (
                            <li>{ item }</li>
                        ))
                    }
    
                </ErrorField>
            </ErrorFieldOuter>
        ) : null }
        </>

    )

}

export default EmailDesignBasicTextInput;

const MiddleAddLink = styled.div`
    display:flex;
    flex-direction:column;
    align-content:center;
    align-items:center;
    justify-content:center;
    padding:10px;
`



const ArrowRow = styled.div`
    display:flex;
    flex-direction:column;
    align-content:center;
    align-items:center;
    justify-content:center;
    padding:10px;
`

const ArrowRowRow = styled.div`
    display:flex;
    flex-direction:row;
    align-content:center;
    align-items:center;
    justify-content:center;
`

const LinkLabel = styled.div`
    display:flex;
    flex-grow:0;
    flex-shrink:0;
    width:80px;
    font-size:14px;

`

const AddButton = styled.div``

const Container = styled.div`
    display:flex;
    justify-content:${props => !props.setAlign ? `flex-start` : props.setAlign};
    align-items:center;
    position:relative;
    border-radius:5px;
    outline:none;
    border:1px solid transparent;
    width:${props => !props.setWidth ? `100%` : props.setWidth};
    border:none;
    ${props => props.additionalStyling}
    ${props => props.errorFormating ? (`
        background: #FFF2F2;
        border: 1px solid #FF7F7F;
    `) : null }

    &:hover {
        border:1px dashed blue;
    }

    
`

const TopOfShowDropDown = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:100%;
    padding:10px;
   
`

const BottomOfShowDropDown = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:100%;
    padding:10px;
    padding-top:0px;
`

const OuterAddButton = styled.div`
    display:flex;
    flex-shrink:0;
    flex-grow:0;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:25px;
    height:25px;
    border-radius:5px;
    background:rgb(0, 205, 112);
    position:relative;
    transition: all 0.5s;
    
    &:hover {
        background:rgb(0, 205, 82);
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
        transition: all 0.0s;
    }

    &:active {
        background:rgb(0, 205, 112);
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 1px 0px, rgba(0, 0, 0, 0.06) 0px 3px 1px 0px;
    }
`

const EachElementOuter = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    padding-top:10px;
    width:100%;
`

const EachElement = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    padding:20px;
    background:rgb(245, 250, 251);
    font-size:13px;
    border-radius:10px;
`


const EachElementMain = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    padding:20px;
    background:${props => props.active ? '#f5fafb' : '#afeae3'};
    font-size:13px;
    border-radius:10px;
    border:1px solid #d9d9d9;
    color:rgb(0, 146, 255);
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
`

const InnerEachElementText = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    padding-left:10px;
    width:100%;
`

const ErrorFieldOuter = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:100%;
`

const ErrorField = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    padding:10px;
    font-size:14px;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
    list-style:none;

    & > li {
        color: red;
        margin-left:5px;

    }
`

const AddLink = styled.input`
    width:100%;
    height:100%;
    border:none;
    outline:none;
    border-radius:5px;
    padding:5px;
    resize:none;
    text-align:left;
    font-size:16px;
    z-index:1010;
    background:none;
    line-height: default;
    margin-left:10px;
    margin-right:10px;
    font-size:13px;
`

const ShowDropAvailable = styled.div`
    position:absolute;
    display: ${props => props.active ? 'flex' : 'none'};
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:center;
    top:100%;
    width:150%;
    background:#ffffff;
    z-index:10000;
    border-radius:25px;
    left:-25%;
    z-index:10000;
    margin-top:10px;
    padding:10px;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;

`

const TextLink = styled.span`
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-content:center;
    align-items:flex-start;
    width:100%;
    font-size:13px;
    font-weight:bold;
    padding:10px;
    padding-top:0px;
`


const SelectedLink = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-content:center;
    align-items:flex-start;
    width:100%;
    background: rgba(0, 146, 255,0.3);
    color:#FFFFFF;
    border-radius:25px;
`


const InputComponent = styled.input`
    width:100%;
    height:100%;
    border:none;
    outline:none;
    border-radius:5px;
    padding:5px;
    resize:none;
    ${props => props.symbol ? `padding-left:50px` : null};
    text-align:${props=> !props.setTextAlign ? 'left' : props.setTextAlign};
    font-size:16px;
    z-index:1010;
    background:none;
    line-height: default;
    &::placeholder {
        color: rgba(255,255,255,0.5);
    }

    ${props => props.additionalStyling}

    ${props => props.typeOf !== 'no-outline' ? (` 

    `
    
    ) : null}
   
`

const CharCounter = styled.div`
    position:absolute;
    right:20px;
    display:flex;
    align-items:center;
    justify-content:center;
    ${props => props.aboveMax ? `color: red` : null};
`

const ShowSymbol = styled.div`
    position:absolute;
    left:15px;
    display:flex;
    align-items:center;
    justify-content:center;
`

const ShowSymbolRight = styled.div`
    position:absolute;
    flex-direction:row;
    right:15px;
    display:flex;
    align-items:center;
    justify-content:center;
`


const ButtonLittlePositioned = styled.button`
    color: #ffffff;
    box-sizing:border-box;
    font-size:13px;
    outline:0;
    margin-left:10px;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:25px;
    height:25px;
    border-radius:30px;
    transition: all 0.5s;
    background:transparent;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 1.0;
    z-index:100000;

    &:hover {
        transition: all 0.3s;
        transform: rotate(90deg);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.8);
    }


`