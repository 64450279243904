import React, { useEffect } from 'react';
import styled from 'styled-components';

import useTitle from '../../../hooks/useTitle';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../state/actions';

import { elements } from '../../../components';

const PageContainer = ({ children, pageReady, pageTitle, sticky, shadow, goBackLink, cover, menu, pageRef, topPadding, pageFocusMenu }) => {


    const { updateSticky } = bindActionCreators(actions, useDispatch());
    const { updateShadow } = bindActionCreators(actions, useDispatch());
    const { updateMenu } = bindActionCreators(actions, useDispatch());
    const { updatePageRef } = bindActionCreators(actions, useDispatch());
    const { updatePageFocusMenu } = bindActionCreators(actions, useDispatch());
    const { updateGoBackLink } = bindActionCreators(actions, useDispatch());

    useEffect(() => {
        updateSticky(sticky)
        updateShadow(shadow)
        updateMenu(menu)
        updatePageRef(pageRef)
        updatePageFocusMenu(pageFocusMenu)
        updateGoBackLink(goBackLink)

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageFocusMenu])

    useTitle(pageTitle);

    return (

        <PageContainerStyled 
            cover={cover} 
            topPadding={topPadding}>

            {!pageReady ? 

                <elements.PageSpinner backgroundColor='#0092FF' color='#ffffff' size='large' /> 
                
                : (
                    <>
                        { children }
                    </>
                )
            }

        </PageContainerStyled>

    )

}



export default PageContainer;

const PageContainerStyled = styled.div`
    display:flex;
    height:100vh;
    width:100vw;
    padding-top:${props => !props.topPadding ? '0px' : '60px'};
    flex-direction:column;
    position:relative;
    box-sizing:border-box;
    z-index:${props => props.cover ? '1' : '1'};
    background: ${props => props.cover ? `rgb(0,146,255)` : `transparent`};
`