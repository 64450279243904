// -------------------------------
// TEXTUAL EXPORTS

// Display to the console
module.exports.console = {
  START_MESSAGE_DEV: `Echo Notify Development Environment`,
  START_MESSAGE_PROD: `EchoNotify.com © Dylan Carter`
}


// Display to the console
module.exports.tabbar = {
  GENERAL: `EchoNotify - Data-Driven Email Marketing at the Speed of Economics`
}

// Throughout application
module.exports.app = {
  TITLE_LOGIN: `Log In`,
  TITLE_JOIN: `Begin`
}
