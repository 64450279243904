import React from 'react';
import styled from 'styled-components';



const CallToAction = ({ titleOne, titleTwo, subTitle, callToActionText }) => {
    return (
        <Container>
            <CallToActionContents>
                <BigTitle>{titleOne}</BigTitle>
                <BigTitle>{titleTwo}</BigTitle>
                <SubTitle>{subTitle}</SubTitle>
                <CallToActionButton>{callToActionText}</CallToActionButton>
            </CallToActionContents>
        </Container>

    );
}


export default CallToAction;


const Container = styled.div`
    width:100%;
    display:flex;
    flex-grow:1;
    box-sizing:border:box;
    flex-direction:column;
    align-items:center;
    align-content:center;
    justify-content:center;
    position:relative;
    z-index:10000;
`

const CallToActionContents = styled.div`
    display:flex;
    flex-grow:0;
    position:relative;
    box-sizing:border:box;
    flex-direction:column;
    align-items:center;
    align-content:center;
    justify-content:center;
    background-color:rgba(0, 146, 255, 0.5);
    box-shadow: rgba(0, 146, 255, 0.5) 0px 7px 20px 20px;
    padding:20px;
    border-radius:100px;
    flex-wrap: nowrap;
`


const BigTitle = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    flex-wrap: nowrap;
    flex-grow:0;
    flex-shrink: 0;
    font-size:60px;
    font-family: newFontFaceLight, Arial, sans-serif;
    color:#f9f9f9;
`  


const SubTitle = styled.div`
    display:flex;
    flex-grow:0;
    flex-shrink: 0;
    color:#ffffff;
    font-family: newFontFaceLight, Arial, sans-serif;
    font-size:25px;
    padding: 20px 0px;
    color:#f9f9f9;
`



const CallToActionButton = styled.button`
    background: none;
    border:#ffffff;
    color:#ffffff;
    box-sizing:border-box;
    border-radius:5px;
    font-size:13px;
    padding: 10px 0px;
    outline:0;
    font-size:20px;
    cursor:pointer;
    transition: all 0.5s;
    background: #4CD964;
    transform: scale(1.0);
    transition: all 0s;
    width:300px;
    box-shadow: rgba(249, 249, 249, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px; 

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
        background: #4CD964;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 0px 5px 0px, rgba(0, 0, 0, 0.06) 0px 0px 5px 0px;
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
        box-shadow: inset rgba(60, 66, 87, 0.12) 0px 0px 10px 0px, inset rgba(0, 0, 0, 0.12) 0px 0px 10px 0px;
    }
`
