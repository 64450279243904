import { useState, useEffect } from "react";

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../state/actions';

const useGetImageSrc = (serverKey) => {

    const [value, setValue] = useState('');
    const [ready, setReady] = useState(false);

    const { 
        getImage
    } = bindActionCreators(actions, useDispatch());

    useEffect(() => {

        if (!!serverKey) {
            getImage(serverKey, (success, response) => {

                if (success) {
                    setValue(response);
                    setReady(true);
                }
    
            })


        } else {
            setValue('');
            setReady(false);
        };

        return {value, ready};

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverKey])


    return {value, ready};
   
}

export default useGetImageSrc;
