import React from 'react';
import styled from 'styled-components';

import { icons } from '../../../components'

const StarPicker = ({ additionalStyling, value, setValue }) => {

    const updateValue = (option) => {
        setValue(option)
    }

    return (

        <Container additionalStyling={additionalStyling} >

            { value >= 1 ? ( <icons.StarStyled onClick={ () => updateValue(1) }/> ) : ( <icons.StarBorderStyled onClick={ () => updateValue(1) }/> ) }
            { value >= 2 ? ( <icons.StarStyled onClick={ () => updateValue(2) }/> ) : ( <icons.StarBorderStyled onClick={ () => updateValue(2) }/> ) }
            { value >= 3 ? ( <icons.StarStyled onClick={ () => updateValue(3) }/> ) : ( <icons.StarBorderStyled onClick={ () => updateValue(3) }/> ) }
            { value >= 4 ? ( <icons.StarStyled onClick={ () => updateValue(4) }/> ) : ( <icons.StarBorderStyled onClick={ () => updateValue(4) }/> ) }
            { value >= 5 ? ( <icons.StarStyled onClick={ () => updateValue(5) }/> ) : ( <icons.StarBorderStyled onClick={ () => updateValue(5) }/> ) }

        </Container>

    )

}

export default StarPicker;


const Container = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    position:relative;
    width:300px;
    z-index:3000;
    padding-left:20px;
    padding-right:20px;

    ${props => props.additionalStyling}
`
