import axios from 'axios';
import { JSONResponseToConsole } from '../index';

import {
    AUTH_USER,
    AUTH_ERROR
}  from '../types';

import { 
    CONNECTION_TO_API_NOT_WORKING,
    INCORRECT_CREDENTIALS
} from '../errors';

import { 
    ROUTE_AUTH_SIGNUP, 
    ROUTE_AUTH_SIGNIN, 
    ROUTE_AUTH_FORGOT, 
    ROUTE_AUTH_RESET 
} from '../routes';

// ---------------------------
// SIGN UP

const handleServerErrors = (dispatch, callback, e) => {


    let message = '';

    if (e.response && e.response.status === 401) {
        message = INCORRECT_CREDENTIALS.description;
    } else {
        message = CONNECTION_TO_API_NOT_WORKING.description
    }

    dispatch({ type: AUTH_ERROR, payload: message });
    callback(false, message, true);

}



export const signup = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_AUTH_SIGNUP, formProps);

        JSONResponseToConsole(`POST TO ${ROUTE_AUTH_SIGNUP} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;
        const { prompt, token, name, displayPicture, error } = payload;

        if (success) {
            localStorage.setItem('token', token);
            localStorage.setItem('name', name);
            localStorage.setItem('displayPicture', displayPicture);
            dispatch({ type: AUTH_USER, payload: { token, name, displayPicture } });
        }

        callback(success, prompt, false, error);
        
    } catch (e) {

        handleServerErrors(dispatch, callback, e);

    }
}



// ---------------------------
// SIGN IN

export const login = (formProps, callback) => async dispatch => {
    
    try {
        const response = await axios.post(ROUTE_AUTH_SIGNIN, formProps);
        JSONResponseToConsole(`POST TO ${ROUTE_AUTH_SIGNIN} endpoint`, response);

        // Unpack the groceries
        const { name, token, displayPicture } = response.data;

        localStorage.setItem('token', token);
        localStorage.setItem('name', name);
        localStorage.setItem('displayPicture', displayPicture)
        dispatch({ type: AUTH_USER, payload: { token, name, displayPicture }});

        callback(true, null, false, null);

    } catch (e) {

        handleServerErrors(dispatch, callback, e);

    }
}


// ---------------------------
// NO GO

export const reset = (formProps, callback) => async dispatch => {

    try {
        const response = await axios.post(ROUTE_AUTH_RESET, formProps);
        JSONResponseToConsole(`POST TO ${ROUTE_AUTH_RESET} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;
        const { token, name, displayPicture } = payload;

        if (success) {
            localStorage.setItem('token', token);
            localStorage.setItem('name', name);
            localStorage.setItem('displayPicture', displayPicture);
            dispatch({ type: AUTH_USER, payload: { token, name, displayPicture} });
        }

        callback(success, null, false, null);

    } catch (e) {
        
        handleServerErrors(dispatch, callback, e);

    }
}



// ---------------------------
// FORGOT

export const forgot = (formProps, callback) => async dispatch => {
    try {
        const response = await axios.post(ROUTE_AUTH_FORGOT, formProps);
        JSONResponseToConsole(`POST TO ${ROUTE_AUTH_FORGOT} endpoint`, response);

        const { success, payload, error } = response.data;

        callback(success, payload, false, error);

    } catch (e) {

        handleServerErrors(dispatch, callback, e);

    }
}

// ---------------------------
// SIGN OUT

export const signout = (callback) => async dispatch => {
    dispatch({ type: AUTH_USER, payload: { token: '', name: '', displayPicture: ''}});
    localStorage.removeItem('token');
    callback();
};