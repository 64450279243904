
import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

const useTitle = (title) => {

    const location = useLocation();

    useEffect(() => {

        // if (!title) return;

        const prevTitle = document.title;
        document.title = title + location.pathname;;
        return () => {
            document.title = prevTitle + location.pathname;
        }

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title])

}

export default useTitle;