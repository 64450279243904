
export const createDesign = {

    title: {
        presence: true,
    },

}


export const updateDesign = {

    designID: {
        presence: true,
    },

    design: {
        presence: true,
    },

}


export const deleteDesign = {

    designID: {
        presence: true,
    },

}

export const publishDesign = {

    designID: {
        presence: true,
    },

}


export const addLinkToDesign = {

    designID: {
        presence: true,
    },

    url: {
        presence: true
    }

}