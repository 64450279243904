module.exports = {

    // NAV
    UPDATE_STICKY: 'update_sticky',
    UPDATE_SHADOW: 'update_shadow',
    UPDATE_MENU: 'update_menu',
    UPDATE_PAGEREF: 'update_pageref',
    UPDATE_PAGEFOCUSMENU: 'update_pagefocusmenu',
    UPDATE_GOBACKLINK: 'update_goBackLink',

    // AUTH
    AUTH_USER: 'auth_user',
    AUTH_ERROR: 'auth_error',

    // IMG
    IMAGE_LOADED: 'image_loaded',
    LOAD_DISPLAY_PICTURE: 'load_display_picture',
    
    // PAY
    PAYMENT_INTENT: 'payment_intent',
    PAYMENT_CUSTOMER_CREATE: 'payment_customer_create',
    CREATE_CARD: 'create_card',

}

