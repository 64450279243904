import { 
    UPDATE_STICKY,
    UPDATE_SHADOW,
    UPDATE_MENU,
    UPDATE_PAGEREF,
    UPDATE_PAGEFOCUSMENU,
    UPDATE_GOBACKLINK
} from '../actions/types';


const INITIAL_STATE = {
    sticky: false,
    shadow: false,
    menu: 'none',
    pageRef: '',
    pageFocusMenu: null,
    goBackLink: null
}

const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {

        case UPDATE_STICKY:
            return { ...state, sticky: action.value };
        
        case UPDATE_SHADOW:
            return { ...state, shadow: action.value };

        case UPDATE_MENU:
            return { ...state, menu: action.value };

        case UPDATE_PAGEREF:
            return { ...state, pageRef: action.value };

        case UPDATE_PAGEFOCUSMENU:
            return { ...state, pageFocusMenu: action.value}
        
        case UPDATE_GOBACKLINK:
            return { ...state, goBackLink: action.value}

        default:
            return state;

    }
}

export default reducer;