import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './App.css';
import defaults from './constants/defaults';

import useTitle from './hooks/useTitle';

import { NavBar } from './components/elements';


const App = ({ children }) => {

  useTitle(defaults.tabbar.GENERAL);

  const authenticated = useSelector(state => state.auth.authenticated)
  const user = useSelector(state => state.auth)

  const activeSwitch = useSelector(state => state.nav.sticky) || !!authenticated
  const shadowSwitch = useSelector(state => state.nav.shadow) || !!authenticated
  const menuToDisplay = useSelector(state => state.nav.menu)
  const pageRef = useSelector(state => state.nav.pageRef)
  const pageFocusMenu = useSelector(state => state.nav.pageFocusMenu)
  const goBackLink = useSelector(state => state.nav.goBackLink)

  return (
    <div>
      <NavBar 
        startOn={activeSwitch}
        startOnShadow={shadowSwitch} 
        startMenuToDisplay={menuToDisplay}
        authenticated={!!authenticated}
        user={user}
        pageRef={pageRef}
        pageFocusMenu={pageFocusMenu}
        goBackLink={goBackLink}
      />
      { children }
    </div>
  )

}

export default App;
