export const updateAccount = {

    name: (value, attributes) => {

        return { 
            presence: attributes.email === undefined,
        }
        
    },

    email: (value, attributes) => {

        return { 
            presence: attributes.name === undefined,
            email: attributes.name === undefined
        }
        
    },

}

export const accountExists = {

    email: {
        presence: true,
        email: true
    }

}


export const addAddress = {

    addressLineOne: {
        presence: true
    },

    addressLineTwo: {
        presence: true
    },

    city: {
        presence: true
    },

    state: {
        presence: true
    },

    postcode: {
        presence: true
    },

    country: {
        presence: true
    },

    googlePlacesID: {
        presence: false // Not required so optional
    }

}
